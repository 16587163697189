import React from 'react';
import { Modal } from 'antd';
import IconBuild from '../../../../assets/images/frame.svg';
import Loading from 'components/Loading';

import './styles.scss';

const ModalConfirm = ({
  modal,
  totalCompany,
  visible,
  onCancel,
  title,
  okText,
  cancelText,
  onOk,
  isRemove = true,
  companyCopyRemove,
  isDoneCopyMove,
  dataDetailSavedList,
  loading
}) => {
  const checkModal = () => {
    let text = 'copied'

    if (modal === 'move') {
      text = "moved"
    }

    if (modal === 'add') {
      text = "added"
    }

    return text
  }

  const checkModalConfirm = () => {
    let text = 'copy'

    if (modal === 'move') {
      text = "move"
    }

    if (modal === 'add') {
      text = "add"
    }

    return text
  }

  return (
    <div>
      <Modal
        title={title}
        visible={visible}
        width="60%"
        onCancel={onCancel}
        onOk={onOk}
        okText={okText}
        cancelText={cancelText}
        className={`modal-confirm ${isRemove ? 'is-remove' : ''}`}
      >
        {loading && (
          <div className="loading-box">
            <Loading />
          </div>
        )}
        <div className="list-items">
          <div className="items">
            <img src={IconBuild} alt="IconBuild" />
            <div className="name">
              <h4>
                {companyCopyRemove?.savedListName} <span>({(dataDetailSavedList && isDoneCopyMove) ? dataDetailSavedList?.viewSaveListDetails?.totalRecords : companyCopyRemove?.numOfCompany})</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="content-confirm">
          {
            isDoneCopyMove ?
              <p>
                {totalCompany} {totalCompany > 1 ? 'items' : 'item'} {totalCompany > 1 ? 'have' : 'has'} been {checkModal()} to <span>{companyCopyRemove?.savedListName}</span>
              </p> :
              <p>
                Are you sure you want to {checkModalConfirm()} {totalCompany} {totalCompany > 1 ? 'items' : 'item'} to <span>{companyCopyRemove?.savedListName}</span>?
              </p>
          }
        </div>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
