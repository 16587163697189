import gql from 'graphql-tag';

export const SAVED_SCREENING = gql`
  query savedScreening(
    $fromDate: Date
    $savedListName: String
    $searchType: SearchListType
    $sortBy: SortType
    $toDate: Date
    $page: Int!
    $pageSize: Int!
    $savedListId: Int
  ) {
    savedScreening(
      fromDate: $fromDate
      savedListName: $savedListName
      searchType: $searchType
      sortBy: $sortBy
      toDate: $toDate
      page: $page
      pageSize: $pageSize
      savedListId: $savedListId
    ) {
      items {
        createDate
        numOfCompany
        savedListId
        savedListName
        status
        updatedDate
      }
      hasNextPage
      hasPreviousPage
      totalPage
    }
  }
`;

export const SAVED_LIST_DETAIL = gql`
  query viewSaveListDetails($savedListId: Int!, $page: Int!, $pageSize: Int!, $sortBy: SortType) {
    viewSaveListDetails(savedListId: $savedListId, page: $page, pageSize: $pageSize, sortBy: $sortBy) {
      hasNextPage
      hasPreviousPage
      items {
        en_OrganName
        taxCode
        fiinNumber
        status
        en_RegisteredAddress
        vsicCode
        website
        telephone
        organCode
        vsicIndustry {
          en_VsicName
          vsicName
        }
      }
      page
      pageSize
      totalPage
      totalRecords
    }
  }
`;

export const LIST_NAME = gql`
  query {
    screeningSaveLists {
      createDate
      numOfCompany
      savedListName
      savedListId
    }
  }
`;

export const FIND_OVERLAPS = gql`
  query findOverlaps($saveListId: Int!) {
    findOverlaps(saveListId: $saveListId) {
      numCompany
      savedListId
      savedListName
      organCode
    }
  }
`;

export const SAVED_LIST_COMPANIES_OVERLAPS = gql`
  query savedListCompaniesOverlaps($currentSaveListId: Int!, $targetSaveListId: Int!) {
    savedListCompaniesOverlaps(currentSaveListId: $currentSaveListId, targetSaveListId: $targetSaveListId) {
      organCode
    }
  }
`;
