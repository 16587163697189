import grid2 from '../assets/images/grid-2.svg';
import grid3 from '../assets/images/grid-3.svg';
import grid4 from '../assets/images/grid-4.svg';
import gridStandard from '../assets/images/grid-standard.svg';

export const listGridLayout = [
  {
    text: 'Standard',
    icon: gridStandard,
    value: 'standard'
  },
  {
    text: 'Grid 4',
    icon: grid4,
    value: 'grid4'
  },
  {
    text: 'Grid 3',
    icon: grid3,
    value: 'grid3'
  },
  {
    text: 'Grid 2',
    icon: grid2,
    value: 'grid2'
  }
];
