import React, { useContext, useEffect, useState } from 'react';
import ModalConfirmation from '../../components/ModalConfirmation';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_TRANSACTION_PRICE_QUERY, CREATE_TRANSACTION_MUTATION } from '../../graphql/queries/Transaction';
import { GET_DISCOUNT_QUERY } from '../../graphql/queries/Discount';
import { TRANSACTION_TYPE_ID_VIEW_COMPANY } from '../../constants/TransactionTypeIds';
import globalContext from '../../context/global/globalContext';
import ModalError from '../../components/ModalError';
import { withRouter } from 'react-router';
import { CHECK_PERMISSION_QUERY } from '../../graphql/queries/CheckPermission';
import { CURRENT_BALANCE } from '../../graphql/queries/CurrentBalance';

const CompanyViewedConfirmation = props => {
  const title = 'Company Viewed Confirmation';
  const content = 'Do you confirm to view this company?';

  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasPermissionViewCompany, setHasPermissionViewCompany] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const {
    globalState,
    setCompanyViewedConfirmation,
    setCurrentBalance,
    setTransactionId,
    companyInformation
  } = useContext(globalContext);
  const checkPermissionFlag = globalState.companyViewedConfirmation.checkPermissionFlag;
  const redirectUrl = globalState.companyViewedConfirmation.redirectUrl;
  const organCode = globalState.companyViewedConfirmation.organCode;
  const organName = globalState.companyViewedConfirmation.organName;
  const openTab = globalState.companyViewedConfirmation.openTab;
  const isQuickSearch = globalState.companyViewedConfirmation.isQuickSearch;
  const fromQuickPage = globalState.companyViewedConfirmation.fromQuickPage;

  const [getDataForCheckingPermission, { data: dataCheckpermission }] = useLazyQuery(CHECK_PERMISSION_QUERY, {
    variables: {
      organCode: organCode,
      transactionType: TRANSACTION_TYPE_ID_VIEW_COMPANY
    },
    fetchPolicy: 'cache-and-network'
  });

  const [getTransactionPrice, { data: dataTransactionPrice }] = useLazyQuery(GET_TRANSACTION_PRICE_QUERY, {
    variables: {
      // organCode: organCode,
      transactionTypeId: TRANSACTION_TYPE_ID_VIEW_COMPANY
    }
  });

  const [getDataDiscount, { data: dataDiscount }] = useLazyQuery(GET_DISCOUNT_QUERY, {
    variables: {
      transactionTypeId: TRANSACTION_TYPE_ID_VIEW_COMPANY
    }
  });

  const [currenBlance, { data: dataCurrenBlance }] = useLazyQuery(CURRENT_BALANCE);

  const [createTransactionToViewCompany] = useMutation(CREATE_TRANSACTION_MUTATION);

  const price = dataTransactionPrice?.transactionPrice?.price ?? null;
  const [closeError, setCloseError] = useState(false);

  const closeCompanyViewedConfirmationModal = () => {
    if (closeError) {
      setShowModal(false);
      setHasPermissionViewCompany(null);
      setCompanyViewedConfirmation({
        checkPermissionFlag: false,
        openTab: false
      });
    } else {
      setShowModal(false);
      setHasPermissionViewCompany(null);
      setCompanyViewedConfirmation({
        checkPermissionFlag: false,
        openTab: false
      });
    }
  };

  useEffect(() => {
    if (dataCurrenBlance) {
      setCurrentBalance(dataCurrenBlance.currentBalance.currentBalance);
    }
  }, [dataCurrenBlance]);

  useEffect(() => {
    if (organCode && checkPermissionFlag === true) {
      getDataForCheckingPermission();
    }
  }, [checkPermissionFlag]);

  useEffect(() => {
    if (dataCheckpermission) {
      setHasPermissionViewCompany(dataCheckpermission.checkPermission);
    }
  }, [dataCheckpermission]);

  useEffect(() => {
    if (hasPermissionViewCompany !== null && hasPermissionViewCompany === true) {
      closeCompanyViewedConfirmationModal();
      window.open('/company-information/' + organCode);
    }

    if (organCode && hasPermissionViewCompany === false) {
      setShowModal(true);
      getTransactionPrice();
      getDataDiscount();
    }
  }, [hasPermissionViewCompany]);

  const onConfirm = () => {
    setLoadingConfirm(true);

    createTransactionToViewCompany({
      variables: {
        organCode: organCode,
        transactionType: TRANSACTION_TYPE_ID_VIEW_COMPANY
      }
    })
      .then(response => {
        setLoadingConfirm(false);
        setTransactionId(response.data.createTransaction);
        setShowModal(false);
        currenBlance();
        setHasPermissionViewCompany(null);
        if (openTab) {
          setCompanyViewedConfirmation({
            checkPermissionFlag: false,
            showModalSuccessful: false
          });
          localStorage.setItem(
            'infoCompanyViewed',
            JSON.stringify({
              organCode: organCode,
              transactionId: response.data.createTransaction
            })
          );
          window.open('/company-information/' + organCode);
        } else {
          setCompanyViewedConfirmation({
            checkPermissionFlag: false,
            showModalSuccessful: true
          });
        }
        if (isQuickSearch) {
          props.history.push('/company-information/' + organCode);
        }

        // window.location.reload();
      })
      .catch(error => {
        setLoadingConfirm(false);
        setCloseError(true);
        closeCompanyViewedConfirmationModal();
        setErrorMessage(error.message.replace('GraphQL error: ', ''));
        setShowError(true);
      });
  };

  const onCancel = () => {
    closeCompanyViewedConfirmationModal();
    if (redirectUrl) {
      props.history.push('/dashboard/');
    }
  };

  const closeErrorModal = () => {
    setShowError(false);
  };

  const showPrice = true;

  return (
    <div className="modal-confirm">
      <ModalConfirmation
        visible={showModal}
        title={title}
        organName={organName}
        content={content}
        price={price}
        dataTransactionPrice={dataTransactionPrice}
        dataDiscount={dataDiscount}
        onOk={onConfirm}
        onCancel={onCancel}
        loadingConfirm={loadingConfirm}
        fromQuickPage={fromQuickPage}
        showPrice={showPrice}
      />

      <ModalError visible={showError} content={errorMessage} onOk={closeErrorModal} onCancel={closeErrorModal} />
    </div>
  );
};

export default withRouter(CompanyViewedConfirmation);
