import React, { memo } from 'react';
import { Button, Modal } from 'antd';
import IconError from '../../assets/images/error.svg';
import './styles.scss';

const ModalError = memo(
  ({ visible, content, onOk, onCancel, title = 'Error', okText = 'Close', maskClosable = false }) => {
    return (
      <Modal
        title={title}
        visible={visible}
        width="55%"
        maskClosable={maskClosable}
        wrapClassName="modal modal-hide-footer"
        onCancel={onCancel}
      >
        <div className="error">
          <div className="content">
            <img className="" src={IconError} alt="error" />
            <div>{content}</div>
          </div>

          <div className="modal-footer">
            <Button type="primary" className="ok" onClick={onOk}>
              {okText}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalError;
