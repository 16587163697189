import format from 'date-fns/format';

export function formatDate(value, formatType = 'MMM dd, yyyy') {
  return value ? format(new Date(value), formatType) : '';
}

export function formatDateTime(value, formatType = 'MMM dd, yyyy HH:mm:ss') {
  return value ? format(new Date(value), formatType) : '';
}
export function formatYear(value, formatType = 'yyyy') {
  return value ? format(new Date(value), formatType) : '';
}
export default {
  formatDate,
  formatYear,
  formatDateTime
};
