import gql from 'graphql-tag';

export const GET_TRANSACTION_PRICE_QUERY = gql`
  query transactionPrice($transactionTypeId: Int!) {
    transactionPrice(transactionTypeId: $transactionTypeId) {
      discountPrice
      discountRate
      discountName
      price
    }
  }
`;

export const CREATE_TRANSACTION_MUTATION = gql`
  mutation createTransaction($organCode: String!, $transactionType: Int!) {
    createTransaction(organCode: $organCode, transactionType: $transactionType)
  }
`;

export const LATEST_TRANSACTION_ID = gql`
  query latestTransactionId($transactionTypeId: Int!) {
    latestTransactionId(transactionTypeId: $transactionTypeId)
  }
`;
