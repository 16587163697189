import React, { useReducer } from 'react';
import listSelectedFilterReducer from './listSelectedFilterReducer';
import listSelectedFilterContext from './listSelectedFilterContext';
import {
  SET_LIST_SELECTED_FILTER,
  REMOVE_LIST_SELECTED_FILTER,
  CLEAR_ALL_FILTER_SELECTED,
  SET_TOTAL_RESULT,
  SET_DATA_QUERY_BASIC_INFORMATION,
  SET_DATA_QUERY_INDUSTRIES,
  SET_DATA_QUERY_GROUP,
  SET_DATA_QUERY_FINANCIAL,
  SET_ACTIVE_SCREEN,
  SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW,
  SET_DATA_SELECTED_INFORMATION,
  SET_DATA_VIEW_SETTING,
  SET_DATA_CURRENCY,
  SET_SCREENING_ID,
  SET_DATA_SEARCH_RESULT,
  SET_SAVED_LIST_ITEM,
  SET_ACTIVE_MODAL,
  SET_NUMBER_SELECTED_COMPANIES,
  SET_DISCOUNT_VIEW_SETTING
} from '../types';

const ListSelectedFilterState = props => {
  const initialState = {
    removeID: null,
    clearAllFilter: false,
    listFilterSelected: [],
    listKeyTabVsicIndustry: [],
    listKeyTabIcbIndustry: [],
    keyVsicDisplaySelected: [],
    keyIcbDisplaySelected: [],
    dataOrigin: [],
    dataOriginVsicIndustry: [],
    dataOriginIcbIndustry: [],
    yearReports: [],
    totalResult: 0,
    basicInformation: {},
    groups: {},
    industries: {},
    financials: {},
    activeScreen: 'advance-search',
    activeModalSelectCompanyInformation: false,
    dataSelectedInformation: [],
    dataViewSetting: {},
    dataCurrency: 'VND',
    screeningId: null,
    activeModal: null,
    dataSearchCriteria: [],
    dataSearchResult: {
      selectedCompany: [],
      excludedCompany: [],
      isCheckAll: false
    },
    savedListItem: {},
    numberSelectedCompanies: 0,
    dataDiscount: {
      listDiscount: [],
      discountRate: 0,
      discountPrice: 0
    }
  };

  const [state, dispatch] = useReducer(listSelectedFilterReducer, initialState);

  const setDataSelectedFilter = data => {
    dispatch({
      type: SET_LIST_SELECTED_FILTER,
      payload: data
    });
  };

  const removeItemFilter = data => {
    dispatch({
      type: REMOVE_LIST_SELECTED_FILTER,
      payload: data
    });
  };

  const clearAllFilterSelected = () => {
    dispatch({
      type: CLEAR_ALL_FILTER_SELECTED
    });
  };

  const setTotalResult = result => {
    dispatch({
      type: SET_TOTAL_RESULT,
      payload: result
    });
  };

  const setQueryFilterBasicInFormation = value => {
    dispatch({
      type: SET_DATA_QUERY_BASIC_INFORMATION,
      payload: value
    });
  };

  const setQueryFilterIndustries = value => {
    dispatch({
      type: SET_DATA_QUERY_INDUSTRIES,
      payload: value
    });
  };

  const setQueryFilterGroup = value => {
    dispatch({
      type: SET_DATA_QUERY_GROUP,
      payload: value
    });
  };

  const setQueryFilterFinancial = value => {
    dispatch({
      type: SET_DATA_QUERY_FINANCIAL,
      payload: value
    });
  };

  const setActiveScreen = value => {
    dispatch({
      type: SET_ACTIVE_SCREEN,
      payload: value
    });
  };

  const setActiveModalSelectCompanyInformation = value => {
    dispatch({
      type: SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW,
      payload: value
    });
  };

  const setSelectedInformation = value => {
    dispatch({
      type: SET_DATA_SELECTED_INFORMATION,
      payload: value
    });
  };

  const setDataSearchResult = value => {
    dispatch({
      type: SET_DATA_SEARCH_RESULT,
      payload: value
    });
  };

  const setSavedListItem = value => {
    dispatch({
      type: SET_SAVED_LIST_ITEM,
      payload: value
    });
  };

  const setActiveModal = value => {
    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: value
    });
  };

  const setDataViewSetting = value => {
    dispatch({
      type: SET_DATA_VIEW_SETTING,
      payload: value
    });
  };

  const setStateCurrency = value => {
    dispatch({
      type: SET_DATA_CURRENCY,
      payload: value
    });
  };

  const setScreeningId = value => {
    dispatch({
      type: SET_SCREENING_ID,
      payload: value
    });
  };

  const setNumberSelectedCompanies = value => {
    dispatch({
      type: SET_NUMBER_SELECTED_COMPANIES,
      payload: value
    });
  };

  const setDataDiscountViewSetting = value => {
    dispatch({
      type: SET_DISCOUNT_VIEW_SETTING,
      payload: value
    });
  };

  return (
    <listSelectedFilterContext.Provider
      value={{
        dataListSelectFilter: state,
        dataDiscount: state.dataDiscount,
        setDataSelectedFilter,
        removeItemFilter,
        clearAllFilterSelected,
        setTotalResult,
        setQueryFilterBasicInFormation,
        setQueryFilterIndustries,
        setQueryFilterGroup,
        setQueryFilterFinancial,
        setActiveScreen,
        setActiveModalSelectCompanyInformation,
        setSelectedInformation,
        setDataViewSetting,
        setStateCurrency,
        setScreeningId,
        setDataSearchResult,
        setSavedListItem,
        setActiveModal,
        setNumberSelectedCompanies,
        setDataDiscountViewSetting
      }}
    >
      {props.children}
    </listSelectedFilterContext.Provider>
  );
};
export default ListSelectedFilterState;
