import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainMenu from '../components/MainMenu/MainMenu';
import HeaderReport from '../Report/components/HeaderReport';
import CompanyViewedConfirmation from '../../pages/CompanyViewedConfirmation';
import './styles.scss';

class GridLayout extends PureComponent {
  render() {
    if (this.props.location.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: this.props.location }
          }}
        />
      );
    }
    const { children } = this.props;
    return (
      <div className="layout-grid-report">
        <Header />
        <div id="container">
          <MainMenu history={this.props.history} pathname={this.props.history.location.pathname} />
          <section id="main-body">
            <HeaderReport />
            <div id="main-container-grid" data-component="">
              {children}
            </div>
            <CompanyViewedConfirmation />
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default GridLayout;
