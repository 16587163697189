import gql from 'graphql-tag';

export const ALERT_NOTIFICATION = gql`
	query alertNotification($page: Int!, $pageSize: Int!) {
		alertNotification(page: $page, pageSize: $pageSize) {
      items {
				isRead
        userSettingId
        en_Content
        createDate
        alertId
      }
      page
      pageSize
      totalPage
      totalRecords
    }
	}
`;
