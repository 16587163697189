import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

const PrivateRoute = ({ component: Component, layout: Layout, routes, ...rest }) => {
  const [status, setstatus] = useState();
  const authContext = useContext(AuthContext);
  const { signinRedirect, isAuthenticated } = authContext;

  useEffect(() => {
    const checkLogin = async () => {
      const result = await isAuthenticated();
      setstatus(result);
    };

    checkLogin();
  }, []);

  return (
    <Route
      {...rest}
      render={renderProps => {
        if (status === false) {
          signinRedirect();
        }
        if (!Component) {
          return null;
        }
        return Layout ? (
          <Layout {...renderProps}>{Component ? <Component {...renderProps} routes={routes} /> : null}</Layout>
        ) : (
          <Component {...renderProps} routes={routes} />
        );
      }}
    />
  );
};

export default PrivateRoute;
