import gql from 'graphql-tag';

export const INFO_COMPANY_QUERY = gql`
  query companyReport($organCode: String!) {
    companyReport(organCode: $organCode) {
      comTypeCode
      yearReport
      organName
      en_OrganShortName
      taxCode
      en_OrganName
      vsicCodeLevel3
      vsicCode
      fiscalYearEnd
      updateDate
    }
  }
`;

export const COMPANY_REPORT_SOURCES = gql`
  query companyReportSources {
    companyReportSources {
      text
      value
    }
  }
`;
