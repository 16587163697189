import React, { useReducer } from 'react';
import globalReducer from './globalReducer';
import GlobalContext from './globalContext';
import {
  SET_FIIN_RATING,
  SET_TOKEN_REPORT,
  SET_QUERY_FINANCIAL_STATEMENTS,
  SET_COMPANY_VIEWED_CONFIRMATION,
  SET_COMPANY_INFORMATION,
  SET_CURRENT_BALANCE,
  SET_CURRENCY_UNIT_FINANCIAL,
  SET_TOP_COMPANIES,
  SET_LIST_ID_ALERT_READED,
  SET_TOTAL_UNREAD,
  SET_COMPARISON,
  SET_TOTAL_ASSETS,
  SET_CURRENCY_UNIT_COMPANY_INFORMATION,
  SET_CURRENCY_TOP_COMPANY,
  SET_CURRENCY_INDUSTRY_PERFORMANCE,
  SET_CURRENCY_STATISTICS,
  SET_CURRENCY_CREDIT_LIMIT,
  SET_CURRENCY_LIQUIDITY,
  SET_CURRENCY_PROFITABILITY,
  SET_CURRENCY_FILING_STATUS,
  SET_CURRENCY_CAPITAL_ADEQUACY,
  SET_CURRENCY_OPERATIONAL,
  SET_KEEP_CURRENCY_FINANCIAL,
  SET_TRANSACTION_ID,
  SET_ACTIVE_TAB_PAYMENT_METHOD,
  SET_CREDIT_PRICE_PAYMENT_METHOD,
  SET_KEY_TAB_CHECK,
  SET_KEEP_UNIT_FINANCIAL_VALUE,
  SET_KEEP_UNIT_ANALYSIS_VALUE,
  SET_KEEP_UNIT_INDUSTRY_VALUE,
  SET_ALER_NOTIFICATION_GLOBAL,
  SET_COMPANY_STATUSES,
  SET_ERROR_GET_COMPANY_INFORMATION,
  SET_SUBSCRIPTION,
  SET_LOCATIONS,
  SET_LEGAL_FORMS,
  SET_LEGAL_REGULATION,
  SET_ICB_INDUSTRIES,
  SET_VSIC_INDUSTRIES,
  SET_ADVANCE_SEARCH_FILTER
} from '../types';

const GlobalState = props => {
  const initialState = {
    alertNotificationGlobal: {},
    companyViewedConfirmation: {
      checkPermissionFlag: false,
      showModalSuccessful: false,
      organCode: null,
      organName: null
    },
    dataFiinRating: {},
    tokenReport: '',
    queryFinancialStatements: {
      IsAudit: null,
      IsConsolidated: null,
      IsDirect: null,
      ExternalSourceName: null
    },
    companyInformation: {},
    errorGetCompanyInformation: false,
    currentBalance: 0,
    currencyUnitFinancial: {
      currency: 'VND',
      unit: ''
    },
    gridLayout: {},
    topCompanies: {
      top: 10,
      value: 'totalAssets',
      label: 'Total Assets'
    },
    comparison: '',
    totalAssets: 5,
    listIdAlertReaded: [],
    totalUnRead: 0,
    currencyUnitCompanyInformation: {
      currency: 'VND',
      unit: ''
    },
    currencyTopCompany: 'VND',
    currencyIndustryPerformance: 'VND',
    currencyStatistics: 'VND',
    currencyCreditLimit: 'VND',
    currencyLiquidity: 'VND',
    currencyProfitability: 'VND',
    currencyFilingStatus: 'VND',
    currencyCapitalAdequacy: 'VND',
    currencyOperational: 'VND',
    keepCurrencyFinancial: {
      financialSummary: 'VND',
      balanceSheetTable: 'VND',
      balanceSheetChart: 'VND',
      incomeStatementTable: 'VND',
      incomeStatementChart: 'VND',
      cashFlowStatementTable: 'VND',
      cashFlowStatementChart: 'VND'
    },
    transactionId: null,
    activeTabPayment: '1',
    creditPayment: 0,
    pricePayment: 0,
    keyTabCheckPayment: 1,
    keepUnitFinancial: {
      financialSummary: '',
      balanceSheetTable: '',
      balanceSheetChart: '',
      incomeStatementTable: '',
      incomeStatementChart: '',
      cashFlowStatementTable: '',
      cashFlowStatementChart: ''
    },
    keepUnitAnalysis: {
      liquidity: '',
      profitability: '',
      filingStatus: ''
    },
    keepUnitIndustry: {
      industryStatistics: '',
      industryPerformance: ''
    },
    companyStatuses: {},
    subscription: {},
    locations: [],
    legalForms: [],
    legalRegulation: [],
    vsicIndustries: [],
    icbIndustries: [],
    advanceSearchFilter: []
  };
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const setKeepUnitIndustry = value => {
    dispatch({
      type: SET_KEEP_UNIT_INDUSTRY_VALUE,
      payload: value
    });
  };

  const setKeepUnitAnalysis = value => {
    dispatch({
      type: SET_KEEP_UNIT_ANALYSIS_VALUE,
      payload: value
    });
  };

  const setKeepUnitFinancial = value => {
    dispatch({
      type: SET_KEEP_UNIT_FINANCIAL_VALUE,
      payload: value
    });
  };

  const setKeepCurrencyFinancial = value => {
    dispatch({
      type: SET_KEEP_CURRENCY_FINANCIAL,
      payload: value
    });
  };

  const setCurrencyOperational = value => {
    dispatch({
      type: SET_CURRENCY_OPERATIONAL,
      payload: value
    });
  };

  const setCurrencyCapitalAdequacy = value => {
    dispatch({
      type: SET_CURRENCY_CAPITAL_ADEQUACY,
      payload: value
    });
  };

  const setCurrencyFilingStatus = value => {
    dispatch({
      type: SET_CURRENCY_FILING_STATUS,
      payload: value
    });
  };

  const setCurrencyProfitability = value => {
    dispatch({
      type: SET_CURRENCY_PROFITABILITY,
      payload: value
    });
  };

  const setCurrencyLiquidity = value => {
    dispatch({
      type: SET_CURRENCY_LIQUIDITY,
      payload: value
    });
  };

  const setCurrencyCreditLimit = value => {
    dispatch({
      type: SET_CURRENCY_CREDIT_LIMIT,
      payload: value
    });
  };

  const setCurrencyStatistics = value => {
    dispatch({
      type: SET_CURRENCY_STATISTICS,
      payload: value
    });
  };

  const setCurrencyIndustryPerformance = value => {
    dispatch({
      type: SET_CURRENCY_INDUSTRY_PERFORMANCE,
      payload: value
    });
  };

  const setCurrencyTopCompany = value => {
    dispatch({
      type: SET_CURRENCY_TOP_COMPANY,
      payload: value
    });
  };

  // Set topCompanies
  const setTopCompanies = value => {
    dispatch({
      type: SET_TOP_COMPANIES,
      payload: value
    });
  };

  // Set setComparison to template
  const setComparison = value => {
    dispatch({
      type: SET_COMPARISON,
      payload: value
    });
  };

  // Set setComparison to template
  const setTotalAssets = value => {
    dispatch({
      type: SET_TOTAL_ASSETS,
      payload: value
    });
  };

  // Set exchangeRates
  const setExchangeRates = value => {
    if (value !== 'undefined' && value?.exchangeRates) {
      localStorage.setItem('exchangeRates', JSON.stringify(value.exchangeRates));
    }
  };

  //Set industries
  const setIndustries = value => {
    if (value !== 'undefined' && value?.vsicIndustries) {
      dispatch({
        type: SET_VSIC_INDUSTRIES,
        payload: value.vsicIndustries
      });
    }
    if (value !== 'undefined' && value?.icbIndustries) {
      // localStorage.setItem('icbIndustries', JSON.stringify(value.icbIndustries));
      dispatch({
        type: SET_ICB_INDUSTRIES,
        payload: value.icbIndustries
      });
    }
  };

  const setAdvanceSearchFilter = value => {
    if (value !== 'undefined' && value?.screeningIndicator?.screeningParameters) {
      // localStorage.setItem('screeningParameters', JSON.stringify(value.screeningIndicator.screeningParameters));
      dispatch({
        type: SET_ADVANCE_SEARCH_FILTER,
        payload: value?.screeningIndicator?.screeningParameters
      });
    }
  };

  //Set legalForms
  const setLegalForms = value => {
    if (value !== 'undefined' && value?.legalForms) {
      // localStorage.setItem('legalForms', JSON.stringify(value.legalForms));
      dispatch({
        type: SET_LEGAL_FORMS,
        payload: value?.legalForms
      });
    }
  };

  //set Location
  const setLocation = value => {
    if (value !== 'undefined' && value?.locations) {
      // localStorage.setItem('locations', JSON.stringify(value.locations));
      // set('locations', JSON.stringify(value.locations));
      dispatch({
        type: SET_LOCATIONS,
        payload: value?.locations
      });
    }
  };

  //set LegalRegulation
  const setLegalRegulation = value => {
    if (value !== 'undefined' && value?.companyReportLegalRegulations) {
      // localStorage.setItem('legalRegulation', JSON.stringify(value.companyReportLegalRegulations));
      dispatch({
        type: SET_LEGAL_REGULATION,
        payload: value?.companyReportLegalRegulations
      });
    }
  };

  //Set fiinRating
  const setFiinRating = value => {
    if (typeof value !== 'undefined' || value !== null) {
      dispatch({
        type: SET_FIIN_RATING,
        payload: value
      });
    }
  };

  //Set tokenReport
  const setTokenReport = value => {
    dispatch({
      type: SET_TOKEN_REPORT,
      payload: value
    });
  };

  const setCompanyViewedConfirmation = value => {
    dispatch({
      type: SET_COMPANY_VIEWED_CONFIRMATION,
      payload: value
    });
  };

  const setQueryFinancialStatements = value => {
    dispatch({
      type: SET_QUERY_FINANCIAL_STATEMENTS,
      payload: value
    });
  };

  const setCompanyInformation = value => {
    dispatch({
      type: SET_COMPANY_INFORMATION,
      payload: value
    });
  };

  const setCurrentBalance = value => {
    dispatch({
      type: SET_CURRENT_BALANCE,
      payload: value
    });
  };

  const setCurrencyUnitFinancial = value => {
    dispatch({
      type: SET_CURRENCY_UNIT_FINANCIAL,
      payload: value
    });
  };

  const setListIdAlertReaded = value => {
    dispatch({
      type: SET_LIST_ID_ALERT_READED,
      payload: value
    });
  };

  // set total unread
  const setTotalUnRead = value => {
    dispatch({
      type: SET_TOTAL_UNREAD,
      payload: value
    });
  };

  const setCurrencyUnitCompanyInformation = value => {
    dispatch({
      type: SET_CURRENCY_UNIT_COMPANY_INFORMATION,
      payload: value
    });
  };

  const setTransactionId = value => {
    dispatch({
      type: SET_TRANSACTION_ID,
      payload: value
    });
  };

  const setActiveTabPayment = data => {
    dispatch({
      type: SET_ACTIVE_TAB_PAYMENT_METHOD,
      payload: data
    });
  };

  const setCreditPricePayment = data => {
    dispatch({
      type: SET_CREDIT_PRICE_PAYMENT_METHOD,
      payload: data
    });
  };

  const setKeyTabCheckPayment = data => {
    if (data > initialState.keyTabCheckPayment) {
      dispatch({
        type: SET_KEY_TAB_CHECK,
        payload: data
      });
    }
  };

  const setAlertNotificationGlobal = data => {
    dispatch({
      type: SET_ALER_NOTIFICATION_GLOBAL,
      payload: data
    });
  };

  const setCompanyStatuses = data => {
    dispatch({
      type: SET_COMPANY_STATUSES,
      payload: data
    });
  };

  const setErrorGetCompanyInformation = data => {
    dispatch({
      type: SET_ERROR_GET_COMPANY_INFORMATION,
      payload: data
    });
  };

  const setSubscription = data => {
    dispatch({
      type: SET_SUBSCRIPTION,
      payload: data
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        globalState: state,
        dataQueryFinancialStatements: state.queryFinancialStatements,
        currentBalance: state.currentBalance,
        setCurrentBalance,
        setQueryFinancialStatements,
        setExchangeRates,
        setIndustries,
        setAdvanceSearchFilter,
        dataFiinRating: state.dataFiinRating,
        setCompanyViewedConfirmation,
        setFiinRating,
        tokenReport: state.tokenReport,
        setTokenReport,
        setCompanyInformation,
        companyInformation: state.companyInformation,
        setCurrencyUnitFinancial,
        currencyUnitFinancial: state.currencyUnitFinancial,
        setTopCompanies,
        setLegalForms,
        setLocation,
        listIdAlertReaded: state.listIdAlertReaded,
        setListIdAlertReaded,
        totalUnRead: state.totalUnRead,
        setTotalUnRead,
        setComparison,
        setTotalAssets,
        setCurrencyUnitCompanyInformation,
        currencyUnitCompanyInformation: state.currencyUnitCompanyInformation,
        setLegalRegulation,
        currencyTopCompany: state.currencyTopCompany,
        setCurrencyTopCompany,
        currencyIndustryPerformance: state.currencyIndustryPerformance,
        setCurrencyIndustryPerformance,
        currencyStatistics: state.currencyStatistics,
        setCurrencyStatistics,
        currencyCreditLimit: state.currencyCreditLimit,
        setCurrencyCreditLimit,
        currencyLiquidity: state.currencyLiquidity,
        setCurrencyLiquidity,
        currencyProfitability: state.currencyProfitability,
        setCurrencyProfitability,
        currencyFilingStatus: state.currencyFilingStatus,
        setCurrencyFilingStatus,
        currencyCapitalAdequacy: state.currencyCapitalAdequacy,
        setCurrencyCapitalAdequacy,
        currencyOperational: state.currencyOperational,
        setCurrencyOperational,
        keepCurrencyFinancial: state.keepCurrencyFinancial,
        setKeepCurrencyFinancial,
        transactionId: state.transactionId,
        setTransactionId,
        setActiveTabPayment,
        setCreditPricePayment,
        setKeyTabCheckPayment,
        setKeepUnitFinancial,
        keepUnitFinancial: state.keepUnitFinancial,
        setKeepUnitAnalysis,
        keepUnitAnalysis: state.keepUnitAnalysis,
        setKeepUnitIndustry,
        keepUnitIndustry: state.keepUnitIndustry,
        activeTabPayment: state.activeTabPayment,
        keyTabCheckPayment: state.keyTabCheckPayment,
        creditPayment: state.creditPayment,
        pricePayment: state.pricePayment,
        showModalSuccessful: state.companyViewedConfirmation.showModalSuccessful,
        alertNotificationGlobal: state.alertNotificationGlobal,
        setAlertNotificationGlobal,
        setCompanyStatuses,
        companyStatuses: state.companyStatuses,
        errorGetCompanyInformation: state.errorGetCompanyInformation,
        setErrorGetCompanyInformation,
        setSubscription,
        subscription: state.subscription,
        locations: state.locations,
        legalForms: state.legalForms,
        legalRegulation: state.legalRegulation,
        icbIndustries: state.icbIndustries,
        vsicIndustries: state.vsicIndustries,
        advanceSearchFilter: state.advanceSearchFilter
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
export default GlobalState;
