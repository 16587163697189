import gql from 'graphql-tag';

export const FEATURE_LIST_FEEDBACK = gql`
	query {
		featureList {
			en_FeatureName
      featureId
		}
	}
`;

