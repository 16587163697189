import React, { useContext } from 'react';
import { MenuMyFiinGate } from 'constants/MenuMyFiinGate';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { INFO_COMPANY_QUERY } from '../../../../graphql/queries/FinancialsSummary';
import GlobalContext from 'context/global/globalContext';
import './styles.scss';

const Menu = props => {
  // const { organCode } = useParams();
  const globalContext = useContext(GlobalContext);
  const { companyInformation } = globalContext;
  // const { data } = useQuery(INFO_COMPANY_QUERY, { variables: { organCode: organCode } });
  const data = companyInformation;
  let pathname = props.location.pathname.split('/');

  const listMenus = MenuMyFiinGate.map((value, key) => {
    let link = value.link;
    let active = '/' + pathname[1] === value.link ? 'active' : '';

    return (
      <li key={key} className={active}>
        {data?.companyReport?.comTypeCode === 'NH' && value.link === '/rating-credit-limit' ? (
          <div className="disable">
            <img src={value.icon} alt={value.icon} />
          </div>
        ) : (
          <Tooltip placement="right" title={value.text}>
            <Link to={link}>
              <img src={value.icon} alt={value.icon} />
            </Link>
          </Tooltip>
        )}
      </li>
    );
  });
  return (
    <div className="sidebar-report">
      <div className="submenu">
        <div className="nav-menu">
          <nav className="navbar navbar-default">
            <ul className="menubar submenubar">{listMenus}</ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Menu;
