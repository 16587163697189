import React, { useState, useEffect } from 'react';
import { Modal, Input } from 'antd';
import IconSearch from '../../../../assets/images/icon-search.svg';
import IconBuild from '../../../../assets/images/frame.svg';
import './styles.scss';
import { LIST_NAME } from 'graphql/queries/SavedList';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { CREATE_NEW_LIST } from 'graphql/mutations/SaveList';

const ModalCopyRemove = ({ visible, onCancel, onRemove, title, onCopyRemove, modalName, savedListId }) => {
  const [name, setName] = useState('');
  const [getData, { data }] = useLazyQuery(LIST_NAME);
  const [getListName, { data: dataListName }] = useLazyQuery(LIST_NAME);
  const [listName, setListName] = useState([]);
  const [createSaveList] = useMutation(CREATE_NEW_LIST);

  useEffect(() => {
    if (visible) {
      getData();
    }
  }, []);

  useEffect(() => {
    const listDetails = data?.screeningSaveLists.filter(el => el.savedListId !== parseInt(savedListId));
    setListName(listDetails);
  }, [data]);

  useEffect(() => {
    setName('');
    const listDetails = dataListName?.screeningSaveLists.filter(el => el.savedListId !== parseInt(savedListId));
    setListName(listDetails);
    getListName();
  }, [visible]);

  useEffect(() => {
    const listDetails = dataListName?.screeningSaveLists.filter(el => el.savedListId !== parseInt(savedListId));
    setListName(listDetails);
  }, [dataListName]);

  const onChangeName = e => {
    const valueInput = e.target.value;
    const search = valueInput.toLowerCase();

    setName(valueInput);
    const listDetails = data?.screeningSaveLists.filter(el => el.savedListId !== parseInt(savedListId));
    const listFilter = listDetails.filter(el => el.savedListName.toLowerCase().includes(search.trim()));
    setListName(listFilter);
  };

  const copyRemove = el => {
    onCopyRemove(el);
  };

  const createdSaveList = () => {
    createSaveList({
      variables: {
        name: name.trim()
      }
    })
      .then(result => {
        const data = {
          numOfCompany: 0,
          savedListName: name.trim(),
          savedListId: parseInt(result.data.screeningCreateSaveList)
        };

        onCopyRemove(data);
      })
      .catch(error => {});
  };

  const footerDelete = (
    <div className="remove-footer">
      <button className="ant-btn btn-cancel" onClick={onCancel}>
        Cancel
      </button>
      <button className="ant-btn btn ant-btn-primary" onClick={onRemove}>
        Remove
      </button>
    </div>
  );

  return (
    <div>
      <Modal
        title={title}
        visible={visible}
        width="60%"
        onCancel={onCancel}
        footer={modalName !== 'remove' ? null : footerDelete}
        className="modal-saved-list"
      >
        {modalName !== 'remove' ? <p className="pick-list">Pick a list</p> : <p className="sure">Are you sure?</p>}

        {modalName !== 'remove' && (
          <div className="input-search u-mb-10">
            <img className="filter-icon" src={IconSearch} alt="iconSearch" />
            <Input
              placeholder="Search list name or create new list"
              maxLength={100}
              value={name}
              onChange={e => onChangeName(e)}
            />
          </div>
        )}

        {name && (
          <div className="block-create-list">
            <p>
              Create: <span>{name}</span>
            </p>
            {modalName === 'add' ? (
              <button className="btn" onClick={() => createdSaveList()}>
                Create list and Add
              </button>
            ) : (
              <button className="btn" onClick={() => createdSaveList()}>
                Create list and {modalName === 'copy' ? 'Copy' : 'Move'}
              </button>
            )}
          </div>
        )}

        <div className="list-items">
          {listName &&
            modalName !== 'remove' &&
            listName.map(el => {
              return (
                <div className="items" key={el.savedListId}>
                  <img src={IconBuild} alt="IconBuild" />
                  <div className="name">
                    <h4>
                      {el.savedListName} <span>({el.numOfCompany})</span>
                    </h4>
                    {modalName !== 'add' ? (
                      <button className="btn" onClick={() => copyRemove(el)}>
                        {modalName === 'copy' ? 'Copy' : 'Move'}
                      </button>
                    ) : (
                      <button className="btn" onClick={() => copyRemove(el)}>
                        Add
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};

export default ModalCopyRemove;
