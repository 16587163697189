import React from 'react';
import './styles.scss';

const AlertBox = ({ alert, arrayAlertId }) => {
  const formatTimeWaiting = value => {
    let textTimeWaiting = ''
    let currentTime = Date.now();
    let createTime = Date.parse(value)
    const intervals = { second: 1, minute: 60, hour: 3600, day: 86400 }
    let timeWaiting = Math.floor((currentTime - createTime) / 1000);

    switch (true) {
      case timeWaiting <= 30 * intervals.second:
        textTimeWaiting = 'Just now' // <= 30s
        break;
      case timeWaiting > 30 * intervals.second && timeWaiting < 1 * intervals.minute:
        textTimeWaiting = '30s' // > 30s && < 1m
        break;
      case timeWaiting >= 1 * intervals.minute && timeWaiting < 2 * intervals.minute:
        textTimeWaiting = '1m ago' // >= 1m
        break;
      case timeWaiting >= 2 * intervals.minute && timeWaiting < 3 * intervals.minute:
        textTimeWaiting = '2m ago' // >= 2m
        break;
      case timeWaiting >= 3 * intervals.minute && timeWaiting < 5 * intervals.minute:
        textTimeWaiting = '3m ago' // >= 3m
        break;
      case timeWaiting >= 5 * intervals.minute && timeWaiting < 10 * intervals.minute:
        textTimeWaiting = '5m ago' // >= 5m
        break;
      case timeWaiting >= 10 * intervals.minute && timeWaiting < 20 * intervals.minute:
        textTimeWaiting = '10m ago' // >= 10m
        break;
      case timeWaiting >= 20 * intervals.minute && timeWaiting < 30 * intervals.minute:
        textTimeWaiting = '20m ago' // >= 20m
        break;
      case timeWaiting >= 30 * intervals.minute && timeWaiting < 1 * intervals.hour:
        textTimeWaiting = '30m ago' // >= 30m
        break;
      case timeWaiting >= 1 * intervals.hour && timeWaiting < 2 * intervals.hour:
        textTimeWaiting = '1h ago' // >= 1h
        break;
      case timeWaiting >= 2 * intervals.hour && timeWaiting < 3 * intervals.hour:
        textTimeWaiting = '2h ago' // >= 2h
        break;
      case timeWaiting >= 3 * intervals.hour && timeWaiting < 6 * intervals.hour:
        textTimeWaiting = '3h ago' // >= 3h
        break;
      case timeWaiting >= 6 * intervals.hour && timeWaiting < 12 * intervals.hour:
        textTimeWaiting = '6h ago' // >= 6h
        break;
      case timeWaiting >= 12 * intervals.hour && timeWaiting < 1 * intervals.day:
        textTimeWaiting = '12h ago' // >= 12h
        break;
      case timeWaiting >= 1 * intervals.day && timeWaiting < 2 * intervals.day:
        textTimeWaiting = '1 day ago' // >= 1 day
        break;
      case timeWaiting >= 2 * intervals.day && timeWaiting < 3 * intervals.day:
        textTimeWaiting = '2 days ago' // >= 2 day
        break;
      case timeWaiting >= 3 * intervals.day && timeWaiting < 4 * intervals.day:
        textTimeWaiting = '3 days ago' // >= 3 day
        break;
      case timeWaiting >= 4 * intervals.day && timeWaiting < 5 * intervals.day:
        textTimeWaiting = '4 days ago' // >= 4 day
        break;
      case timeWaiting >= 5 * intervals.day && timeWaiting < 6 * intervals.day:
        textTimeWaiting = '5 days ago' // >= 5 day
        break;
      case timeWaiting >= 6 * intervals.day && timeWaiting < 7 * intervals.day:
        textTimeWaiting = '6 days ago' // >= 6 day
        break;
      case timeWaiting >= 7 * intervals.day && timeWaiting < 14 * intervals.day:
        textTimeWaiting = '1 week ago' // >= 7 day
        break;
      case timeWaiting >= 14 * intervals.day && timeWaiting < 21 * intervals.day:
        textTimeWaiting = '2 weeks ago' // >= 14 day
        break;
      case timeWaiting >= 21 * intervals.day && timeWaiting < 28 * intervals.day:
        textTimeWaiting = '3 weeks ago' // >= 21 day
        break;
      case timeWaiting >= 28 * intervals.day:
        textTimeWaiting = '4 weeks ago' // >= 28 day
        break;
      default:
        break;
    }

    return textTimeWaiting
  }

  return (
    <li className={`alert-item ${(arrayAlertId && arrayAlertId.length > 0) ? !arrayAlertId.includes(alert.alertId) ? 'alert-readed' : '' : alert.isRead ? 'alert-readed': ''}`}>
      <div className="alert-content">{alert.en_Content}</div>
      <div className="alert-time">{formatTimeWaiting(alert.createDate)}</div>
    </li>
  );
};

export default AlertBox;