import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainMenu from '../components/MainMenu/MainMenu';
import './styles.scss';
import CompanyViewedConfirmation from '../../pages/CompanyViewedConfirmation';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import $ from 'jquery';
import globalContext from '../../context/global/globalContext';

class Main extends PureComponent {
  static globalState = globalContext;
  componentDidMount() {
    client
      .query({
        query: gql`
          {
            subscription {
              endDate
              isOnOff
              isValid
              startDate
            }
          }
        `
      })
      .then(result => {
        this.context.setSubscription(result?.data?.subscription);
        if (!result.data.subscription || !result.data.subscription.isValid) {
          $('.autho-message').show();
          $('.menu-item').addClass('isdisabled');
          $('.wrap-logo').addClass('isdisabled');
          $('.search-all').attr('readOnly', true);
        } else {
          $('.autho-message').hide();
          $('.menu-item').removeClass('isdisabled');
          $('.wrap-logo').removeClass('isdisabled');
          $('.search-all').attr('readOnly', false);
        }
      });
  }
  render() {
    if (this.props.location.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: this.props.location }
          }}
        />
      );
    }
    const { children } = this.props;
    return (
      <div className="main-web">
        <Header />

        <div id="container">
          <MainMenu history={this.props.history} pathname={this.props.history.location.pathname} />
          <section id="main-body">
            <div className="gutter-example" id="main-container">
              <div className="autho-message">
                <span>Your subscription has not been purchased or has been expired</span>
              </div>
              {children}
            </div>
            <CompanyViewedConfirmation />
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

Main.contextType = globalContext;

export default Main;
