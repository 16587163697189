import gql from 'graphql-tag';

export const CHECK_PERMISSION_QUERY = gql`
  query checkPermission($organCode: String!, $transactionType: Int!) {
    checkPermission(organCode: $organCode, transactionType: $transactionType)
  }
`;
export const GET_SUBSCRIPTION_ONLY = gql`
  query {
    subscription {
      endDate
      isOnOff
      startDate
      isValid
    }
  }
`;
