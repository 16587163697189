import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { userManager, getUser } from '../utils/userManager';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const { extensions } = graphQLErrors[0];
    getUser().then(user => {
      if (extensions?.code && extensions.code === 'AUTH_NOT_AUTHENTICATED' && user.accessToken) {
        userManager.signinRedirect();
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL_GRAPHQL
});

const setAuthorizationLink = setContext((_request, _previousContext) => {
  return getUser().then(user => {
    return { headers: { Authorization: `Bearer ${user.access_token}` } };
  });
});

// setup your client
export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, setAuthorizationLink, httpLink]),
  cache: new InMemoryCache(),
  name: 'react-web-client',
  version: '1.0',
  queryDeduplication: false
});
