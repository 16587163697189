import React from 'react';
import AuthContext from './authContext';
import { userManager, getUser } from '../../utils/userManager';

const AuthState = props => {
  userManager.events.addSilentRenewError(e => {
    userManager.getUser().then(user => {
      if (!user || user.expired) {
        userManager.signoutRedirect();
      }
    });
  });

  const isAuthenticated = async () => {
    const userInfo = await getUser();
    return !!userInfo && !userInfo.expired;
  };

  const signinCallback = () => {
    return userManager.signinRedirectCallback();
  };

  const signinRedirect = () => {
    userManager.signinRedirect({
      extraQueryParams: {
        returnUrl: window.location.pathname
      }
    });
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  return (
    <AuthContext.Provider
      value={{
        signinRedirect,
        isAuthenticated,
        logout,
        signinCallback
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthState;
