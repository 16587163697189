const { REACT_APP_API_URL, REACT_APP_API_TIMEOUT } = process.env;
const { REACT_APP_REFRESH_TOKEN_KEY = 'reefreshToken' } = process.env;

export const API_URL = REACT_APP_API_URL;
export const API_TIMEOUT = REACT_APP_API_TIMEOUT;
export const REFRESH_TOKEN_KEY = REACT_APP_REFRESH_TOKEN_KEY;
export const CANCELLED_REQUEST = 'CANCELLED_REQUEST';

const data = {
  API_URL,
  REFRESH_TOKEN_KEY,
  API_TIMEOUT: REACT_APP_API_TIMEOUT
};

export default data;
