class Route {
  static page403 = `/403`;
  static page404 = `/404`;
  static page500 = `/500`;
  static login = '/login';
  static account = '/account';
  static dashboard = '/dashboard';
  static register = '/register';
}

export default Route;
