import gql from 'graphql-tag';

export const ADVANCED_SEARCH_LIST_FILTER = gql`
  query screeningIndicator {
    screeningIndicator {
      yearReports
      screeningParameters {
        en_IndicatorName
        hasChildren
        indicatorName
        indicatorId
        indicatorLevel
        indicatorType
        parentIndicatorId
        multiple
        comTypeCode
        indicatorField
        toRangeValue
        fromRangeValue
        indicatorUnitCode
      }
    }
  }
`;

export const ADVANCED_SEARCH_ICB_INDUSTRY = gql`
  query icbIndustries {
    icbIndustries {
      en_IcbName
      icbLevel
      parentIcbCode
      parentIndustryID
      industryID
      icbCode
    }
  }
`;

export const ADVANCED_SEARCH_VSIC_INDUSTRY = gql`
  query vsicIndustries {
    vsicIndustries {
      en_VsicName
      parentVsicCode
      vsicLevel
      vsicOrder
      vsicCode
    }
  }
`;

export const DATA_COMPANY_STATUS = gql`
  query companyStatuses {
    companyStatuses {
      text
      value
    }
  }
`;

export const DATA_LEGAL_FORMS = gql`
  query legalForms {
    legalForms {
      text
      value
    }
  }
`;

export const TOTAL_RESULTS = gql`
  query screeningTotalResults($term: ScreeningQueryType!) {
    screeningTotalResults(term: $term)
  }
`;

export const DATA_OPTION_LOCATION = gql`
  query locations {
    locations {
      currencyCode
      locationCode
      parentLocationCode
      en_LocationName
      locationLevel
    }
  }
`;

export const DATA_COMPANY_REPORT_LEGAL_REGULATIONS = gql`
  query companyReportLegalRegulations {
    companyReportLegalRegulations {
      text
      value
    }
  }
`;

export const SCREEN_CREATE_VIEW_RESULTS = gql`
  mutation screeningCreate($numberOfCompanies: Int!, $pageSize: Int!, $term: ScreeningInputType!) {
    screeningCreate(numberOfCompanies: $numberOfCompanies, pageSize: $pageSize, term: $term) {
      screeningId
    }
  }
`;

export const SCREENING_SAVE_SEARCH = gql`
  mutation screeningSave($screeningId: Int!, $screeningName: String!) {
    screeningSave(screeningId: $screeningId, screeningName: $screeningName)
  }
`;
