import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FEATURE_LIST_FEEDBACK } from 'graphql/queries/Feedback';
import { FEEDBACK_SUBMIT } from 'graphql/mutations/FeedbackSubmit';
import { Button, Input, Select, Icon } from 'antd';
import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

const GiveFeedback = props => {
  const data = props?.listFeedBack;
  const [feedbackSubmitAdd] = useMutation(FEEDBACK_SUBMIT);
  const feedbackForm = document.querySelector('.feedback');
  const body = document.querySelector('body');
  const [valueContents, setValueContents] = useState('');
  const [valueFeature, setValueFeature] = useState();

  // Hidden form Feedback
  const hidenFeedback = () => {
    feedbackForm.classList.remove('active');
    body.classList.remove('display-overflow');
    setValueFeature();
    setValueContents('');
  };

  const cancelFeedback = () => {
    hidenFeedback();
  };

  const handleChangeContents = e => {
    setValueContents(e.target.value);
  };

  const handleChangeFeature = value => {
    setValueFeature(value);
  };

  const submitFeedback = () => {
    feedbackSubmitAdd({ variables: { content: valueContents.trim(), featureId: valueFeature } }).then(result => {
      if (result.data.feedbackSubmitAdd) {
        hidenFeedback();
      }
    });
  };

  return (
    <div className="feedback">
      <div className="feedback-wrapper">
        <div className="feedback-feature">
          <h3 className="title-feature">Feature</h3>
          {data && data.featureList.length > 0 && (
            <Select
              placeholder="Select option"
              suffixIcon={<Icon type="caret-down" />}
              onChange={handleChangeFeature}
              value={valueFeature}
            >
              {data.featureList.map((el, index) => (
                <Option value={el.featureId} key={index}>
                  {el.en_FeatureName}
                </Option>
              ))}
            </Select>
          )}
        </div>

        <div className="feedback-contents">
          <div className="text-center">Contents</div>
          <TextArea value={valueContents || ''} onChange={handleChangeContents} maxLength={1000} />
        </div>

        <div className="feedback-action">
          <Button className="btn btn-cancel" onClick={cancelFeedback}>
            Cancel
          </Button>
          <Button
            className="btn btn-submit"
            disabled={!valueContents.trim() || valueFeature === undefined}
            onClick={submitFeedback}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="feedback-overlay" onClick={hidenFeedback}></div>
    </div>
  );
};

export default GiveFeedback;
