import React, { memo } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import iconCompany from '../../assets/images/company.svg';
import './styles.scss';
import Loading from '../../components/Loading';
import iconInfoSortBy from '../../assets/images/icon-info-sort-by.svg';
import TooltipDiscount from '../../components/TooltipDiscount';

const ModalConfirmation = memo(
  ({
    visible,
    loading,
    title,
    organName,
    content,
    dataTransactionPrice,
    dataDiscount,
    onOk,
    onCancel,
    okText = 'Confirm',
    cancelText = 'Cancel',
    checkNoteContent = false,
    showIconCompany = true,
    className = '',
    isShowTextNote,
    loadingConfirm = false,
    showPrice=false
  }) => {
    const isShowPrice = () => {
      if (dataTransactionPrice?.transactionPrice?.price !== undefined) {
        return true;
      }

      return false;
    };

    const discountRate = dataTransactionPrice?.transactionPrice?.discountRate;

    const tooltipDiscountRate = () => {
      if (dataDiscount?.discounts.length > 0) {
        return <TooltipDiscount discounts={dataDiscount?.discounts} />;
      } else {
        return 'No discount';
      }
    };

    return (
      <Modal visible={visible} onCancel={onCancel} width="60%" footer={null} wrapClassName={className}>
        <div className="confirmation">
          <div className="title">{title}</div>
          {(showIconCompany || organName) && (
            <div className="sub-title">
              {showIconCompany && <img className="image-icon" src={iconCompany} />}
              <div className="name">
                <span>{organName}</span>
              </div>
            </div>
          )}

          {loading && (
            <div className="loading-box">
              <Loading />
            </div>
          )}

          {!loading && (
            <div className="confirm-box">
              <div className={`text-confirm ${discountRate > 0 && !checkNoteContent ? 'show-discount' : ''}`}>
                {isShowPrice() && discountRate > 0 && !checkNoteContent && (
                  <div className="discount-price">
                    {discountRate > 0 && (
                      <div className="price">
                        Price:{' '}
                        <span>{checkNoteContent ? 0 : dataTransactionPrice?.transactionPrice?.price} Credit</span>
                      </div>
                    )}
                    {discountRate > 0 && (
                      <div className="price">
                        <span className="label-discount-rate">
                          Discount Rate{' '}
                          <Tooltip placement="top" title={tooltipDiscountRate} overlayClassName="tooltip-discount">
                            <img src={iconInfoSortBy} className="info-tooltip-modal" />
                          </Tooltip>
                          :
                        </span>{' '}
                        <span>{discountRate * 100}%</span>
                      </div>
                    )}
                    <div className="price">
                      Total:{' '}
                      <span>
                        {discountRate > 0
                          ? dataTransactionPrice?.transactionPrice?.discountPrice
                          : checkNoteContent
                          ? 0
                          : dataTransactionPrice?.transactionPrice?.price}{' '}
                        Credit
                      </span>
                    </div>
                  </div>
                )}
                <div className="content">
                  {content}
                  {/* {checkNoteContent ? '' : ''} */}
                  {isShowTextNote && (
                    <p className="note-content">
                      {checkNoteContent ? '*' : ''}* Please be aware that the currency and unit you applied for Balance
                      Sheet Section will be used for downloaded report
                    </p>
                  )}
                </div>
                {discountRate === 0 && checkNoteContent === false ? (
                  <div className="price">
                    Total: <span>{dataTransactionPrice?.transactionPrice?.price} Credit</span>
                  </div>
                ) : (
                  ''
                )}

                {/* {showPrice && (<div className="price">
                    Total: <span>{dataTransactionPrice?.transactionPrice?.price} Credit</span>
                  </div>)} */}
              </div>
              <div className="button-confirm">
                <button type="button" className="btn custom-button" onClick={onCancel}>
                  {cancelText}
                </button>
                <Button type="primary" loading={loadingConfirm} className="btn" onClick={onOk}>
                  {okText}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
);

export default ModalConfirmation;
