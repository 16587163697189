/* eslint no-console: 0 */
import axios from 'axios';
import { API_URL, API_TIMEOUT } from './constants';
import { getUser } from './userManager';

const client = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  headers: {
    'content-type': 'application/json'
  },
  responseType: 'json',
  transformResponse: [].concat(axios.defaults.transformResponse, data => {
    if (data) {
      if (!data.hasOwnProperty('success') || data.success) return data;
      else {
        const err = { data: { code: 400, ...data } };
        throw err;
      }
    } else {
      const err = { data: { code: 400, message: 'BadRequest' } };
      throw err;
    }
  }),
  validateStatus: status => {
    if (status === 403) {
      console.log('Error 403');
    } else {
      return status >= 200 && status < 300; // default
    }
  }
});

client.interceptors.request.use(
  async config => {
    const user = await getUser();
    if (user) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    // Do something before request is sent
    if (config.ignoreAuth) {
      config.validateStatus = status => {
        return status >= 200 && status < 300; // default
      };
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  response => {
    if (response.config.url === '/DownloadDocument/GetFile') {
      return response;
    }

    return response.data;
  },
  error => {
    if (error?.response?.status === 500) {
      console.log('Loi server');
    }
    return Promise.reject(error);
  }
);

export function get(url, params = {}, config = {}) {
  Object.assign(config, {
    params
  });
  return client.get(url, { params, ...config });
}

export function post(url, data = {}, config = {}) {
  return client.post(url, data, config);
}

export function put(url, data = {}, config = {}) {
  return client.put(url, data, config);
}

export function path(url, data = {}, config = {}) {
  return client.patch(url, data, config);
}

export function remove(url, params = {}, config = {}) {
  Object.assign(config, {
    params
  });
  return client.delete(url, { params, ...config });
}

export default {
  get,
  put,
  post,
  delete: remove,
  client
};
