export const SET_LIST_SELECTED_FILTER = 'SET_LIST_SELECTED_FILTER';
export const REMOVE_LIST_SELECTED_FILTER = 'REMOVE_LIST_SELECTED_FILTER';
export const CLEAR_ALL_FILTER_SELECTED = 'CLEAR_ALL_FILTER_SELECTED';
export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_UNIT = 'SET_UNIT';
export const SET_DATA_FINANCIALS = 'SET_DATA_FINANCIALS';
export const SET_EXTERNAL_SOURCE_NAME = 'SET_EXTERNAL_SOURCE_NAME';
export const SET_TOTAL_RESULT = 'SET_TOTAL_RESULT';
export const SET_DATA_QUERY_BASIC_INFORMATION = 'SET_DATA_QUERY_BASIC_INFORMATION';
export const SET_DATA_QUERY_INDUSTRIES = 'SET_DATA_QUERY_INDUSTRIES';
export const SET_DATA_QUERY_GROUP = 'SET_DATA_QUERY_GROUP';
export const SET_DATA_QUERY_FINANCIAL = 'SET_DATA_QUERY_FINANCIAL';
export const SET_INDUSTRIES = 'SET_INDUSTRIES';
export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN';
export const SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW = 'SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW';
export const SET_DATA_SELECTED_INFORMATION = 'SET_DATA_SELECTED_INFORMATION';
export const SET_DATA_VIEW_SETTING = 'SET_DATA_VIEW_SETTING';
export const SET_DATA_CURRENCY = 'SET_DATA_CURRENCY';
export const SET_SCREENING_ID = 'SET_SCREENING_ID';
export const SET_DATA_SEARCH_RESULT = 'SET_DATA_SEARCH_RESULT';
export const SET_SAVED_LIST_ITEM = 'SET_SAVED_LIST_ITEM';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_NUMBER_SELECTED_COMPANIES = 'SET_NUMBER_SELECTED_COMPANIES';
export const SET_DATA_ANALYSIS = 'SET_DATA_ANALYSIS';
export const SET_CASH_CONVERSION_CYCLE_DATA = 'SET_CASH_CONVERSION_CYCLE_DATA';
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_ORGAN_NAME = 'SET_ORGAN_NAME';
export const SET_VSIC_CODE_LEVEL3 = 'SET_VSIC_CODE_LEVEL3';
export const SET_COMPANY_EN_ORGAN_NAME = 'SET_COMPANY_EN_ORGAN_NAME';
export const SET_COMPANY_EN_ORGAN_SHORT_NAME = 'SET_COMPANY_ORGAN_SHORT_NAME';
export const SET_COMPANY_TAX_CODE = 'SET_COMPANY_TAX_CODE';
export const SET_FIIN_RATING = 'SET_FIIN_RATING';
export const SET_INDUSTRY_PERFORMANCE_DATA = 'SET_INDUSTRY_PERFORMANCE_DATA';
export const SET_NUMBER_TOP_COMPANIES = 'SET_NUMBER_TOP_COMPANIES';
export const SET_TOKEN_REPORT = 'SET_TOKEN_REPORT';
export const SET_INDUSTRY_COMPARISON_DATA = 'SET_INDUSTRY_COMPARISON_DATA';
export const SET_COMPANY_VIEWED_CONFIRMATION = 'SET_COMPANY_VIEWED_CONFIRMATION';
export const SET_QUERY_FINANCIAL_STATEMENTS = 'SET_QUERY_FINANCIAL_STATEMENTS';
export const SET_COMPANY_INFORMATION = 'SET_COMPANY_INFORMATION';
export const SET_CURRENT_BALANCE = 'SET_CURRENT_BALANCE';
export const SET_CURRENCY_UNIT_FINANCIAL = 'SET_CURRENCY_UNIT_FINANCIAL';
export const SET_TOP_COMPANIES = 'SET_TOP_COMPANIES';
export const SET_LIST_ID_ALERT_READED = 'SET_LIST_ID_ALERT_READED';
export const SET_TOTAL_UNREAD = 'SET_TOTAL_UNREAD';
export const SET_ORGAN_SHORT_NAME = 'SET_ORGAN_SHORT_NAME';
export const SET_COMPARISON = 'SET_COMPARISON';
export const SET_TOTAL_ASSETS = 'SET_TOTAL_ASSETS';
export const SET_CURRENCY_UNIT_COMPANY_INFORMATION = 'SET_CURRENCY_UNIT_COMPANY_INFORMATION';
export const SET_CURRENCY_TOP_COMPANY = 'SET_CURRENCY_TOP_COMPANY';
export const SET_CURRENCY_INDUSTRY_PERFORMANCE = 'SET_CURRENCY_INDUSTRY_PERFORMANCE';
export const SET_CURRENCY_STATISTICS = 'SET_CURRENCY_STATISTICS';
export const SET_CURRENCY_CREDIT_LIMIT = 'SET_CURRENCY_CREDIT_LIMIT';
export const SET_CURRENCY_LIQUIDITY = 'SET_CURRENCY_LIQUIDITY';
export const SET_CURRENCY_PROFITABILITY = 'SET_CURRENCY_PROFITABILITY';
export const SET_CURRENCY_FILING_STATUS = 'SET_CURRENCY_FILING_STATUS';
export const SET_CURRENCY_CAPITAL_ADEQUACY = 'SET_CURRENCY_CAPITAL_ADEQUACY';
export const SET_CURRENCY_OPERATIONAL = 'SET_CURRENCY_OPERATIONAL';
export const SET_KEEP_CURRENCY_FINANCIAL = 'SET_KEEP_CURRENCY_FINANCIAL';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const SET_ACTIVE_TAB_PAYMENT_METHOD = 'SET_ACTIVE_TAB_PAYMENT_METHOD';
export const SET_CREDIT_PRICE_PAYMENT_METHOD = 'SET_CREDIT_PRICE_PAYMENT_METHOD';
export const SET_UNIT_ABSFS_VALUE = 'SET_UNIT_ABSFS_VALUE';
export const SET_MAX_TAB_ACTIVE = 'SET_MAX_TAB_ACTIVE';
export const SET_KEY_TAB_CHECK = 'SET_KEY_TAB_CHECK';
export const SET_KEEP_UNIT_FINANCIAL_VALUE = 'SET_KEEP_UNIT_FINANCIAL_VALUE';
export const SET_KEEP_UNIT_ANALYSIS_VALUE = 'SET_KEEP_UNIT_ANALYSIS_VALUE';
export const SET_KEEP_UNIT_INDUSTRY_VALUE = 'SET_KEEP_UNIT_INDUSTRY_VALUE';
export const SET_DISCOUNT_VIEW_SETTING = 'SET_DISCOUNT_VIEW_SETTING';
export const SET_ALER_NOTIFICATION_GLOBAL = 'SET_ALER_NOTIFICATION_GLOBAL';
export const SET_COMPANY_STATUSES = 'SET_COMPANY_STATUSES';
export const SET_ERROR_GET_COMPANY_INFORMATION = 'SET_ERROR_GET_COMPANY_INFORMATION';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_LEGAL_FORMS = 'SET_LEGAL_FORMS';
export const SET_LEGAL_REGULATION = 'SET_LEGAL_REGULATION';
export const SET_ICB_INDUSTRIES = 'SET_ICB_INDUSTRIES';
export const SET_VSIC_INDUSTRIES = 'SET_VSIC_INDUSTRIES';
export const SET_ADVANCE_SEARCH_FILTER = 'SET_ADVANCE_SEARCH_FILTER';
