import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import routes from './routes';
import RoutePrivateLayout from './RoutePrivateLayout';
import RoutePublicLayout from './RoutePublicLayout';
import Route from '../constants/Route';
import { Modal } from 'antd';

// Pages
const Page403 = React.lazy(() => import('../components/Errors/Page403'));
const Page404 = React.lazy(() => import('../components/Errors/Page404'));
const Page500 = React.lazy(() => import('../components/Errors/Page500'));

//Loading
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const AppRouter = ({ history }) => {
  const getConfirm = (content, callback) => {
    Modal.confirm({
      title: '',
      content,
      onOk: () => {
        document.getElementById('backAdvancedSearch').click();

        callback(true);
      },
      onCancel: () => {
        callback(false);
      },
      cancelText: 'Cancel',
      okText: 'Leave'
    });
  };
  return (
    <BrowserRouter history={history} getUserConfirmation={getConfirm}>
      <React.Suspense fallback={loading()}>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}

          <RoutePublicLayout path={Route.page404} component={Page404} />
          <RoutePrivateLayout path={Route.page403} component={Page403} />
          <RoutePublicLayout path={Route.page500} component={Page500} />
          <Redirect from="*" to={Route.page404} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
