import gql from 'graphql-tag';

export const EXCHANGE_RATES = gql`
  query {
    exchangeRates {
      averageRate
      currencyCode
      lastDayRate
      year
    }
  }
`;
