import numeral from 'numeral';
import isNotHasValue from '../utils/isNotHasValue';

export function formatStandardNumber(value, chart) {
  if (value < 0 && !chart) {
    value = Math.abs(value);
  }

  return isNotHasValue(value) ? '' : numeral(value).format('0,0.[00]');
}

export function formatRatioScoreNumber(value, chart) {
  if (value < 0 && !chart) {
    value = Math.abs(value);
  }

  return isNotHasValue(value) ? '' : numeral(value).format('0,0.00');
}

export function ratioScoreChartFormat(number) {
  if (number != 0) {
    return formatRatioScoreNumber(number);
  } else {
    return number;
  }
}

export function moneyChartFormat(number, currency, unit, chart= true) {
  if (currency == 'VND' && unit == 'Blank') {
    return formatStandardNumber(number, chart);
  } else {
    return formatRatioScoreNumber(number, chart);
  }
}

export default {
  formatStandardNumber,
  formatRatioScoreNumber,
  ratioScoreChartFormat,
  moneyChartFormat
};
