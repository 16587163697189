import React, { useState, useEffect } from 'react';
import ModalCopyRemove from '../ModalCopyRemove';
import ModalConfirm from '../ModalConfirm';
import './styles.scss';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  SAVE_LIST_ADD_TO_SAVE_LIST,
  SAVE_LIST_ADD_TO_SAVE_LIST_ALL,
  SAVE_LIST_DETAIL_DELETE,
  SAVE_LIST_DETAIL_DELETE_ALL,
  MOVE_SAVE_LIST_DETAIL
} from 'graphql/mutations/SaveList';
import { SAVED_LIST_DETAIL } from 'graphql/queries/SavedList';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ModalError from 'components/ModalError';

const listModal = {
  coppy: 'copy',
  move: 'move',
  remove: 'remove',
  add: 'add'
};

const Modal = ({
  modal = '',
  showModalCopyRemove = false,
  closeModalCopyRemove,
  isAll,
  totalCompany,
  selectedCompany,
  excludedCompany,
  closeConfirmCopyRemove
}) => {
  const history = useHistory();
  const { savedListId } = useParams();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [companyCopyRemove, setCompanyCopyRemove] = useState({});
  const [titleCopyRemove, setTitleCopyRemove] = useState(false);
  const [titleConfirm, setTitleConfirm] = useState(false);
  const [isDoneCopyMove, setDoneCopyMove] = useState(false);
  const [textCancel, setTextCancel] = useState('Cancel');
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  // copy
  const [screeningAddToSaveList] = useMutation(SAVE_LIST_ADD_TO_SAVE_LIST);
  const [savedListDetailsAddAllToSaveList] = useMutation(SAVE_LIST_ADD_TO_SAVE_LIST_ALL);
  //delete
  const [savelistDetailDelete] = useMutation(SAVE_LIST_DETAIL_DELETE);
  const [savedListDetailsDeleteAllInSaveList] = useMutation(SAVE_LIST_DETAIL_DELETE_ALL);

  const [fetchDetailSavadList, { data: dataDetailSavedList }] = useLazyQuery(SAVED_LIST_DETAIL, {
    onCompleted: () => {
      setLoadingConfirm(false);
    }
  });

  useEffect(() => {
    if (modal === listModal.move && isDoneCopyMove) {
      onRemove();
      setDoneCopyMove(true);
    }

    if (isDoneCopyMove) {
      setTextCancel('Close');

      fetchDetailSavadList({
        variables: {
          savedListId: parseInt(companyCopyRemove.savedListId),
          page: 1,
          pageSize: 1
        }
      });

      if (modal === listModal.coppy) {
        setTitleConfirm('Copied successfully');
      }

      if (modal === listModal.add) {
        setTitleConfirm('Added successfully');
      }

      if (modal === listModal.move) {
        setTitleConfirm('Moved successfully');
      }
    }
  }, [modal, isDoneCopyMove]);

  useEffect(() => {
    if (modal) {
      if (modal === listModal.coppy || modal === listModal.move) {
        setTitleCopyRemove(
          `${modal === listModal.coppy ? 'Copy' : 'Move'} ${totalCompany} ${
            totalCompany > 1 ? 'items' : 'item'
          } to list`
        );
        setTitleConfirm(
          `${modal === listModal.coppy ? 'Copy' : 'Move'} ${totalCompany} ${
            totalCompany > 1 ? 'items' : 'item'
          } to list`
        );
      }

      if (modal === listModal.remove) {
        setTitleCopyRemove(`Remove ${totalCompany} ${totalCompany > 1 ? 'items' : 'item'} from list`);
        setTitleConfirm(`Remove ${totalCompany} ${totalCompany > 1 ? 'items' : 'item'} from list`);
      }

      if (modal === listModal.add) {
        setTitleCopyRemove(`Add ${totalCompany} ${totalCompany > 1 ? 'items' : 'item'} from list`);
        setTitleConfirm(`Add ${totalCompany} ${totalCompany > 1 ? 'items' : 'item'} from list`);
      }
    }
  }, [modal, totalCompany]);

  const closeModalConfirm = () => {
    setShowModalConfirm(false);
    setDoneCopyMove(false);

    if (isDoneCopyMove) {
      closeModalCopyRemove(true);
    } else {
      closeConfirmCopyRemove();
    }
  };

  const onConfirmCopyRemove = company => {
    setShowModalConfirm(true);
    setCompanyCopyRemove(company);
    setDoneCopyMove(false);
    closeModalCopyRemove(false);
  };

  const onCopyRemove = () => {
    if (isDoneCopyMove) {
      setShowModalConfirm(false);
      onDoneCopyMoveRemove();

      return;
    }

    setLoadingConfirm(true);

    if (modal === listModal.move) {
      onMove();
    }

    if (modal === listModal.coppy || modal === listModal.add) {
      onCopy();
    }

    if (modal === listModal.remove) {
      onRemove();
    }
  };

  const onCopy = () => {
    let variables = {
      excludedOrganCodes: excludedCompany,
      saveListId: parseInt(savedListId),
      destinationId: parseInt(companyCopyRemove.savedListId)
    };

    if (isAll || (isAll && excludedCompany.length > 0)) {
      savedListDetailsAddAllToSaveList({ variables: variables })
        .then(result => {
          if (modal === listModal.coppy || modal === listModal.add) onCopyDone();
          setDoneCopyMove(true);
        })
        .catch(() => {
          failCopyMove();
          setLoadingConfirm(false);
        });
    } else {
      screeningAddToSaveList({
        variables: {
          organCodes: selectedCompany,
          saveListId: parseInt(companyCopyRemove.savedListId)
        }
      })
        .then(result => {
          if (modal === listModal.coppy || modal === listModal.add) onCopyDone();
          setDoneCopyMove(true);
        })
        .catch(() => {
          failCopyMove();
          setLoadingConfirm(false);
        });
    }
  };

  const onCopyDone = () => {
    if (modal === listModal.coppy) {
      setDoneCopyMove(true);
    }

    if (modal === listModal.add) {
      setDoneCopyMove(true);
    }
  };

  const onDoneCopyMoveRemove = () => {
    closeModalCopyRemove(true);
    setDoneCopyMove(false);

    if (modal === listModal.remove) {
      history.push({
        pathname: '/saved-list/' + savedListId
      });
    } else {
      history.push({
        pathname: '/saved-list/' + companyCopyRemove?.savedListId
      });
    }
  };

  const failCopyMove = () => {
    setShowError(true);
    setShowModalConfirm(false);
    setDoneCopyMove(false);
  };

  const closeErrorModal = () => {
    setShowModalConfirm(true);
    setShowError(false);
  };

  const onMove = () => {
    onCopy();
  };

  const onRemove = () => {
    if (isAll || (isAll && excludedCompany.length > 0)) {
      savedListDetailsDeleteAllInSaveList({
        variables: {
          excludedOrganCodes: excludedCompany,
          saveListId: parseInt(savedListId)
        }
      }).then(result => {
        if (modal === listModal.remove) onDoneCopyMoveRemove();
      });
    } else {
      savelistDetailDelete({
        variables: {
          organCodes: selectedCompany,
          saveListId: parseInt(savedListId)
        }
      }).then(result => {
        if (modal === listModal.remove) onDoneCopyMoveRemove();
      });
    }
  };

  const renderOkText = () => {
    let text = 'Copy';

    if (modal === listModal.move) {
      text = 'Move';
    }

    if (modal === listModal.add) {
      text = 'Add';
    }

    if (isDoneCopyMove && !loadingConfirm) {
      text = 'Go to list';
    }

    return text;
  };

  return (
    <div>
      <ModalCopyRemove
        visible={showModalCopyRemove}
        onCancel={() => closeModalCopyRemove(false)}
        onCopyRemove={onConfirmCopyRemove}
        onRemove={onRemove}
        title={titleCopyRemove}
        modalName={modal}
        savedListId={savedListId}
      />

      <ModalConfirm
        modal={modal}
        totalCompany={totalCompany}
        isDoneCopyMove={isDoneCopyMove}
        dataDetailSavedList={dataDetailSavedList}
        isRemove={false}
        visible={showModalConfirm}
        onCancel={closeModalConfirm}
        onOk={onCopyRemove}
        companyCopyRemove={companyCopyRemove}
        title={titleConfirm}
        okText={renderOkText()}
        cancelText={textCancel}
        loading={loadingConfirm}
      />

      <ModalError
        visible={showError}
        content="Number of companies in one Saved List can NOT be greater than 5000."
        onOk={closeErrorModal}
        onCancel={closeErrorModal}
      />
    </div>
  );
};

export default Modal;
