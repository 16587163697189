import React, { useState, useEffect, useContext } from 'react';
import IconDownload from 'assets/images/icon-dowload.svg';
import IconBuild from 'assets/images/icon-multi-build.svg';
import IconUnaudited from 'assets/images/icon-unaudited.svg';
import IconAudited from 'assets/images/icon-audited.svg';
import IconGrid from 'assets/images/icon-grid.svg';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams, Link, useHistory } from 'react-router-dom';
import { HEADER_REPORT } from 'graphql/queries/HeaderReport';
import { DATA_COMPANY_STATUS } from 'graphql/queries/AdvancedSearch';
import GlobalContext from '../../../../context/global/globalContext';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import ModalNotification from '../../../../components/ModalNotification';
import ModalError from '../../../../components/ModalError';
import { GET_TRANSACTION_PRICE_QUERY, LATEST_TRANSACTION_ID } from '../../../../graphql/queries/Transaction';
import { GET_DISCOUNT_QUERY } from '../../../../graphql/queries/Discount';
import { CURRENT_BALANCE } from '../../../../graphql/queries/CurrentBalance';
import { CHECK_PERMISSION_QUERY } from '../../../../graphql/queries/CheckPermission';
import { TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT } from '../../../../constants/TransactionTypeIds';
import { formatDateTime } from '../../../../mixins/DateFormat';
import { exportCompanyReport } from '../../../../api/FinancialStatements';
import { listGridLayout } from '../../../../constants/GridLayout';
import { saveAs } from 'file-saver';
import { find } from 'lodash';
import { withRouter } from 'react-router';
import useWindowDimensions from '../../../../mixins/CheckResizeWindow';
import './styles.scss';
import Modal from '../../../../pages/SavedList/components/Modal';
import { URL_CONTACT } from '../../../../constants';
import CryptoJS from 'crypto-js';
import { removeAccents } from 'mixins/RemoveAccents';

const base64Key = process.env.REACT_APP_BASE64KEY;
const base64Iv = process.env.REACT_APP_BASE64IV;

const key = CryptoJS.enc.Base64.parse(base64Key);
const iv = CryptoJS.enc.Base64.parse(base64Iv);

const HeaderReport = props => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const globalContext = useContext(GlobalContext);
  const {
    setCompanyInformation,
    dataQueryFinancialStatements,
    currencyUnitFinancial,
    setCurrentBalance,
    showModalSuccessful,
    companyInformation,
    companyStatuses,
    setCompanyStatuses,
    setErrorGetCompanyInformation,
    vsicIndustries
  } = globalContext;
  // const { organCode, grid } = useParams();
  const urlParams = useParams();
  const organCode = urlParams.organCode;

  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [modal, setModal] = useState();
  const [showModalCopyRemove, setShowModalCopyRemove] = useState(false);

  const title = 'Company Report Download Confirmation';
  const content = 'Do you confirm to download this report?';

  const pathname = window.location.pathname.split('/')[1];

  let gridSelected = 'standard';
  let componentSelected = pathname;

  if (pathname === 'grid-layout' && urlParams.grid !== 'undefined') {
    gridSelected = urlParams.grid;
    componentSelected = window.location.pathname.split('/')[2];
  }
  const [getHeaderReport, { data, error: errorName }] = useLazyQuery(HEADER_REPORT);
  const [getCompanyStatus, { data: companyStatus }] = useLazyQuery(DATA_COMPANY_STATUS);

  useEffect(() => {
    if (Object.keys(companyInformation).length === 0) {
      getHeaderReport({
        variables: { organCode }
      });
    }

    if (Object.keys(companyStatuses).length === 0) {
      getCompanyStatus();
    }
  }, []);

  useEffect(() => {
    if (errorName) {
      setErrorGetCompanyInformation(true);
    } else {
      setErrorGetCompanyInformation(false);
    }
  }, [errorName]);

  useEffect(() => {
    if (companyStatus && Object.keys(companyStatus).length > 0) {
      setCompanyStatuses(companyStatus);
    }
  }, [companyStatus]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setCompanyInformation(data);
    }
  }, [data]);

  //FS03-M003
  const [getLatestTransactionId, { data: latestTransactionId }] = useLazyQuery(LATEST_TRANSACTION_ID, {
    variables: {
      transactionTypeId: TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT
    }
  });

  const [getTransactionPrice, { data: dataTransactionPrice }] = useLazyQuery(GET_TRANSACTION_PRICE_QUERY, {
    variables: {
      // organCode: organCode,
      transactionTypeId: TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT
    }
  });

  const [getDataDiscount, { data: dataDiscount }] = useLazyQuery(GET_DISCOUNT_QUERY, {
    variables: {
      transactionTypeId: TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT
    }
  });

  const contentNotification = (
    <div>
      Company Report Download transaction has been proceeded successfully.
      <br />
      For transaction information,{' '}
      <Link to={{ pathname: `/subscription/${latestTransactionId?.latestTransactionId}` }} target="_blank">
        click here
      </Link>
    </div>
  );

  // const transactionError = (
  //   <div>
  //     Your current balance is <span style={{ fontFamily: 'AvenirNext-Demi' }}>NOT</span> enough to perform this
  //     transaction.
  //   </div>
  // );

  const [checkPermission, { data: dataCheckPermission }] = useLazyQuery(CHECK_PERMISSION_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const [currenBlance, { data: dataCurrenBlance }] = useLazyQuery(CURRENT_BALANCE);

  // const { data: priceQuery } = useQuery(GET_TRANSACTION_PRICE_QUERY, {
  //   variables: {
  //     transactionTypeId: TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT
  //   }
  // });

  // const [transaction] = useMutation(CREATE_TRANSACTION);

  const dataHeader = companyInformation?.companyReport ? companyInformation?.companyReport : {};
  const dataCompanyStatus = companyStatuses?.companyStatuses ? companyStatuses?.companyStatuses : {};
  let audit = 'Unaudited';
  // const vsicIndustries = JSON.parse(localStorage.getItem('vsicIndustries'));
  const price = 0;
  let dataIndustries = {};
  let dataStatus = {};
  let heightHeaderReport = 0;
  let heightHeader = 0;
  let paddingTop = 0;

  useEffect(() => {
    if (showModalSuccessful) {
      getHeaderReport({
        variables: { organCode }
      });
    }
  }, [showModalSuccessful]);

  useEffect(() => {
    setTimeout(() => {
      heightHeaderReport = document.getElementById('header-report')
        ? document.getElementById('header-report').offsetHeight
        : 0;
      heightHeader = document.getElementById('header') ? document.getElementById('header').offsetHeight : 0;

      if (heightHeaderReport > 0) {
        paddingTop = heightHeaderReport + heightHeader;
        document.getElementById('main-body').style.paddingTop = paddingTop + 'px';
      }
    }, 1000);
  });

  useEffect(() => {
    if (heightHeaderReport > 0) {
      paddingTop = heightHeaderReport + heightHeader;
      document.getElementById('main-body').style.paddingTop = paddingTop + 'px';
    }
  }, [width]);

  useEffect(() => {
    if (typeof dataCheckPermission !== 'undefined') {
      setPermission(dataCheckPermission.checkPermission);
    }
  }, [dataCheckPermission]);

  useEffect(() => {
    if (dataCurrenBlance) {
      setCurrentBalance(dataCurrenBlance.currentBalance.currentBalance);
    }
  }, [dataCurrenBlance]);

  const enOrganName = companyInformation?.companyReport?.en_OrganName;

  if (dataHeader.isAudit === true) {
    audit = 'Audited';
  }

  if (dataHeader.vsicCode) {
    dataIndustries = find(vsicIndustries, function(o) {
      return o.vsicCode === dataHeader.vsicCode;
    });
  }

  if (dataHeader.status) {
    dataStatus = find(dataCompanyStatus, function(o) {
      return o.value === dataHeader.status;
    });
  }

  const convertOrangeName = data => {
    if (!data) {
      return null;
    }

    if (data?.companyReport?.en_OrganShortName) {
      return data.companyReport.en_OrganShortName;
    } else if (data?.companyReport?.en_OrganName) {
      return data.companyReport.en_OrganName;
    } else if (data?.companyReport?.organName) {
      return data.companyReport.organName;
    } else {
      return null;
    }
  };

  const classStatus = dataStatus?.text ? dataStatus?.text.toLowerCase() : '';
  // const getStoreKey = `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
  // const oidcStorage = JSON.parse(localStorage.getItem(getStoreKey));
  // const access_token = oidcStorage?.access_token ?? '';
  // const access_token = JSON.parse(localStorage.getItem('access_token'));
  const organShortName = convertOrangeName(companyInformation);

  const paramIsAudit = dataQueryFinancialStatements.IsAudit ? dataQueryFinancialStatements.IsAudit : '';
  const paramIsConsolidated = dataQueryFinancialStatements.IsConsolidated
    ? dataQueryFinancialStatements.IsConsolidated
    : null;
  const paramIsDirect = dataQueryFinancialStatements.IsDirect ? dataQueryFinancialStatements.IsDirect : '';
  const paramExternalSourceName = dataQueryFinancialStatements.ExternalSourceName
    ? dataQueryFinancialStatements.ExternalSourceName
    : '';
  const paramTopCompanies = JSON.stringify(globalContext?.globalState?.topCompanies);
  const totalAssets = globalContext?.globalState?.totalAssets;
  const comparison = globalContext?.globalState?.comparison;

  const comTypeCode = companyInformation?.companyReport?.comTypeCode;
  let template = '/template-report-company/';

  if (comTypeCode === 'NH') {
    template = '/template-report-bank/';
  }

  let urlReport = window.location.origin + template + organCode;
  urlReport +=
    '?isAudit=' +
    paramIsAudit +
    '&isConsolidated=' +
    paramIsConsolidated +
    '&isDirect=' +
    paramIsDirect +
    '&externalSourceName=' +
    paramExternalSourceName +
    '&totalAssets=' +
    totalAssets +
    '&comparison=' +
    comparison +
    '&topCompanies=' +
    paramTopCompanies;
  urlReport += '&currency=' + currencyUnitFinancial.currency + '&unit=' + currencyUnitFinancial.unit;

  const getExportFileName = () => {
    const date = new Date();
    let fileName =
      'FiinGate_' +
      companyInformation?.companyReport?.taxCode +
      '_' +
      removeAccents(organShortName) +
      '_Company Report';
    fileName += '_' + formatDateTime(date.getTime(), 'yyyyMMddHHmmss');
    fileName += '.pdf';

    return fileName;
  };

  const fileName = getExportFileName();

  const encrypted = CryptoJS.AES.encrypt(urlReport, key, { iv: iv });
  const urlReportEncrypted = encrypted.toString();
  const paramCurrency = currencyUnitFinancial.currency;

  let paramUnit = currencyUnitFinancial.unit ? currencyUnitFinancial.unit : 'Blank';
  switch (paramUnit) {
    case 'Hundred':
      paramUnit = 'Hundred';
      break;
    case 'Thou.':
      paramUnit = 'Thousand';
      break;
    case 'Mn.':
      paramUnit = 'Million';
      break;
    case 'Bn.':
      paramUnit = 'Billion';
      break;
    case 'Hundred Bn.':
      paramUnit = 'HundredBillion';
      break;
    case 'Thou. Bn.':
      paramUnit = 'ThousandBillion';
      break;

    default:
      paramUnit = 'Blank';
      break;
  }

  const callExportCompanyReport = () => {
    exportCompanyReport(
      organCode,
      paramExternalSourceName,
      paramIsAudit,
      paramIsConsolidated,
      paramIsDirect,
      paramCurrency,
      paramUnit
    )
      .then(response => {
        setLoadingConfirm(false);
        const blob = new Blob([response], {
          type: 'application/pdf'
        });
        saveAs(blob, fileName);
        getLatestTransactionId();
        setTimeout(() => {
          setLoading(false);
          setShowModal(false);
          setShowNotification(true);
        }, 600);
        currenBlance();
      })
      .catch(error => {
        setLoadingConfirm(false);
        const errMsg = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error?.response?.data)));
        setLoading(false);
        setErrorMessage(errMsg.message);
        setShowError(true);
        currenBlance();
      });
  };

  const onConfirm = () => {
    setLoading(true);
    setLoadingConfirm(true);
    callExportCompanyReport();
  };
  const onCancel = () => {
    setShowModal(false);
  };

  const closeNotificationModal = () => {
    setShowNotification(false);
  };

  const closeErrorModal = () => {
    setShowError(false);
  };

  const showModalConfirm = () => {
    checkPermission({
      variables: {
        organCode: organCode,
        transactionType: TRANSACTION_TYPE_ID_DOWNLOAD_COMPANY_REPORT
      }
    });
    getTransactionPrice();
    getDataDiscount();

    setShowModal(true);
  };

  const showGridLayout = () => {
    const menu = document.querySelector('.list-grid-layout'); // Using a class instead, see note below.
    menu.classList.toggle('active');
  };

  const redirectGridLayout = grid => {
    if (grid == 'standard') {
      const dataComponent = document.getElementById('main-container-grid').getAttribute('data-component');
      const standardComponent = dataComponent ? dataComponent : 'company-information';
      props.history.push('/' + standardComponent + '/' + organCode);
    } else {
      props.history.push('/grid-layout/' + componentSelected + '/' + organCode + '/' + grid);
    }
    const menu = document.querySelector('.list-grid-layout');
    menu.classList.remove('active');
  };

  const infoGridSelected = listGridLayout.find(element => element.value === gridSelected);

  const addToSaveList = () => {
    // todo
    setModal('add');
    setShowModalCopyRemove(true);
  };

  const closeModalCopyRemove = isDoneCopyMove => {
    if (isDoneCopyMove) {
      //to do
    }

    setShowModalCopyRemove(false);
  };

  const closeConfirmCopyRemove = () => {
    setShowModalCopyRemove(true);
  };

  return (
    <div className="header-report" id="header-report">
      <div className="wrap-title">
        <h1 className="clearfix">
          <img src={IconBuild} alt="icon-build" />
          <span>{dataHeader.en_OrganName}</span>
        </h1>
        <ul>
          <li>
            <span className={`circle ${classStatus}`}></span>
            {dataStatus?.text}
          </li>
          <li>
            <img src={dataHeader.isAudit === true ? IconAudited : IconUnaudited} alt="icon-audited" />
            {audit}
          </li>
          <li>
            <span className="u-mr-10">VSIC:</span>
            <span>{dataIndustries?.en_VsicName ? dataIndustries.en_VsicName : 'Other'}</span>
          </li>
        </ul>
      </div>
      {props.disableDownload ? (
        <span />
      ) : (
        <div className="wrap-actions">
          <div className="wrap-download">
            <button className="btn u-mr-25" onClick={showModalConfirm}>
              <img src={IconDownload} alt="icon-download" className="u-mr-15" />
              Download Report
            </button>
            <p>
              <a href={URL_CONTACT} target="_blank" rel="noopener noreferrer">
                Contact us for Business Evaluation Report
              </a>
            </p>
          </div>
          <div className="wrap-saved">
            <button className="btn" onClick={() => addToSaveList()}>
              + Add to Saved List
            </button>
            <img src={IconGrid} className="icon-grid-layout" alt="icon-grid" onClick={showGridLayout} />
            <div className="list-grid-layout">
              <div className="list-grid">
                {gridSelected !== 'standard' && (
                  <p className="grid-selected clearfix">
                    <img className="icon-grid" src={infoGridSelected.icon} alt="grid-selected" />
                    <span className="name-grid">{infoGridSelected.text}</span>
                  </p>
                )}
                <ul>
                  {listGridLayout.map((value, key) => {
                    if (value.value !== gridSelected) {
                      return (
                        <li key={key} onClick={() => redirectGridLayout(value.value)}>
                          <img className="icon-grid" src={value.icon} alt={`grid-${key}`} />
                          <span className="name-grid">{value.text}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        modal={modal}
        showModalCopyRemove={showModalCopyRemove}
        closeModalCopyRemove={closeModalCopyRemove}
        closeConfirmCopyRemove={closeConfirmCopyRemove}
        totalCompany={1}
        isAll={false}
        selectedCompany={[organCode]}
        excludedCompany={[]}
      />

      <ModalConfirmation
        visible={showModal}
        title={title}
        organName={enOrganName}
        content={content}
        dataTransactionPrice={dataTransactionPrice}
        dataDiscount={dataDiscount}
        loading={loading}
        onOk={onConfirm}
        onCancel={onCancel}
        checkNoteContent={permission}
        isShowTextNote={true}
        loadingConfirm={loadingConfirm}
        isDownloadCompany={true}
      />

      <ModalNotification
        visible={showNotification}
        title="Company Report Download"
        content={contentNotification}
        onOk={closeNotificationModal}
        onCancel={closeNotificationModal}
      />

      <ModalError visible={showError} content={errorMessage} onOk={closeErrorModal} onCancel={closeErrorModal} />
    </div>
  );
};

export default withRouter(HeaderReport);
