import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import './styles.scss';

class TemplateReport extends PureComponent {
  render() {
    if (this.props.location.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: this.props.location }
          }}
        />
      );
    }
    const { children } = this.props;
    return <div className="layout-report-rating">{children}</div>;
  }
}

export default TemplateReport;
