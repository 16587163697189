import {
  SET_FIIN_RATING,
  SET_TOKEN_REPORT,
  SET_QUERY_FINANCIAL_STATEMENTS,
  SET_COMPANY_VIEWED_CONFIRMATION,
  SET_COMPANY_INFORMATION,
  SET_CURRENT_BALANCE,
  SET_CURRENCY_UNIT_FINANCIAL,
  SET_TOP_COMPANIES,
  SET_LIST_ID_ALERT_READED,
  SET_TOTAL_UNREAD,
  SET_COMPARISON,
  SET_TOTAL_ASSETS,
  SET_CURRENCY_UNIT_COMPANY_INFORMATION,
  SET_CURRENCY_TOP_COMPANY,
  SET_CURRENCY_INDUSTRY_PERFORMANCE,
  SET_CURRENCY_STATISTICS,
  SET_CURRENCY_CREDIT_LIMIT,
  SET_CURRENCY_LIQUIDITY,
  SET_CURRENCY_PROFITABILITY,
  SET_CURRENCY_FILING_STATUS,
  SET_CURRENCY_CAPITAL_ADEQUACY,
  SET_CURRENCY_OPERATIONAL,
  SET_KEEP_CURRENCY_FINANCIAL,
  SET_TRANSACTION_ID,
  SET_ACTIVE_TAB_PAYMENT_METHOD,
  SET_CREDIT_PRICE_PAYMENT_METHOD,
  SET_KEY_TAB_CHECK,
  SET_KEEP_UNIT_FINANCIAL_VALUE,
  SET_KEEP_UNIT_ANALYSIS_VALUE,
  SET_KEEP_UNIT_INDUSTRY_VALUE,
  SET_ALER_NOTIFICATION_GLOBAL,
  SET_COMPANY_STATUSES,
  SET_ERROR_GET_COMPANY_INFORMATION,
  SET_SUBSCRIPTION,
  SET_LOCATIONS,
  SET_LEGAL_FORMS,
  SET_LEGAL_REGULATION,
  SET_ICB_INDUSTRIES,
  SET_VSIC_INDUSTRIES,
  SET_ADVANCE_SEARCH_FILTER
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_FIIN_RATING:
      return {
        ...state,
        dataFiinRating: { ...action.payload }
      };

    case SET_TOKEN_REPORT:
      return {
        ...state,
        tokenReport: action.payload
      };

    case SET_COMPANY_VIEWED_CONFIRMATION:
      return {
        ...state,
        companyViewedConfirmation: action.payload
      };
    case SET_QUERY_FINANCIAL_STATEMENTS:
      return {
        ...state,
        queryFinancialStatements: action.payload
      };
    case SET_COMPANY_INFORMATION:
      return {
        ...state,
        companyInformation: { ...action.payload }
      };
    case SET_CURRENT_BALANCE:
      return {
        ...state,
        currentBalance: action.payload
      };
    case SET_CURRENCY_UNIT_FINANCIAL:
      return {
        ...state,
        currencyUnitFinancial: action.payload
      };
    case SET_TOP_COMPANIES:
      return {
        ...state,
        topCompanies: action.payload
      };
    case SET_LIST_ID_ALERT_READED:
      return {
        ...state,
        listIdAlertReaded: action.payload
      };
    case SET_TOTAL_UNREAD:
      return {
        ...state,
        totalUnRead: action.payload
      };
    case SET_COMPARISON:
      return {
        ...state,
        comparison: action.payload
      };

    case SET_TOTAL_ASSETS:
      return {
        ...state,
        totalAssets: action.payload
      };
    case SET_CURRENCY_UNIT_COMPANY_INFORMATION:
      return {
        ...state,
        currencyUnitCompanyInformation: action.payload
      };
    case SET_CURRENCY_TOP_COMPANY:
      return {
        ...state,
        currencyTopCompany: action.payload
      };
    case SET_CURRENCY_INDUSTRY_PERFORMANCE:
      return {
        ...state,
        currencyIndustryPerformance: action.payload
      };
    case SET_CURRENCY_STATISTICS:
      return {
        ...state,
        currencyStatistics: action.payload
      };
    case SET_CURRENCY_CREDIT_LIMIT:
      return {
        ...state,
        currencyCreditLimit: action.payload
      };
    case SET_CURRENCY_LIQUIDITY:
      return {
        ...state,
        currencyLiquidity: action.payload
      };
    case SET_CURRENCY_PROFITABILITY:
      return {
        ...state,
        currencyProfitability: action.payload
      };
    case SET_CURRENCY_FILING_STATUS:
      return {
        ...state,
        currencyFilingStatus: action.payload
      };
    case SET_CURRENCY_CAPITAL_ADEQUACY:
      return {
        ...state,
        currencyCapitalAdequacy: action.payload
      };
    case SET_CURRENCY_OPERATIONAL:
      return {
        ...state,
        currencyOperational: action.payload
      };
    case SET_KEEP_CURRENCY_FINANCIAL:
      return {
        ...state,
        keepCurrencyFinancial: action.payload
      };
    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload
      };
    case SET_ACTIVE_TAB_PAYMENT_METHOD:
      return {
        ...state,
        activeTabPayment: action.payload
      };
    case SET_CREDIT_PRICE_PAYMENT_METHOD:
      return {
        ...state,
        creditPayment: action.payload.credit,
        pricePayment: action.payload.price
      };
    case SET_KEY_TAB_CHECK:
      return {
        ...state,
        keyTabCheckPayment: action.payload
      };

    case SET_KEEP_UNIT_FINANCIAL_VALUE:
      return {
        ...state,
        keepUnitFinancial: action.payload
      };
    case SET_KEEP_UNIT_ANALYSIS_VALUE:
      return {
        ...state,
        keepUnitAnalysis: action.payload
      };
    case SET_KEEP_UNIT_INDUSTRY_VALUE:
      return {
        ...state,
        keepUnitIndustry: action.payload
      };
    case SET_ALER_NOTIFICATION_GLOBAL:
      return {
        ...state,
        alertNotificationGlobal: { ...action.payload }
      };
    case SET_COMPANY_STATUSES:
      return {
        ...state,
        companyStatuses: { ...action.payload }
      };
    case SET_ERROR_GET_COMPANY_INFORMATION:
      return {
        ...state,
        errorGetCompanyInformation: action.payload
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: { ...action.payload }
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload
      };
    case SET_LEGAL_FORMS:
      return {
        ...state,
        legalForms: action.payload
      };
    case SET_LEGAL_REGULATION:
      return {
        ...state,
        legalRegulation: action.payload
      };
    case SET_VSIC_INDUSTRIES:
      return {
        ...state,
        vsicIndustries: action.payload
      };
    case SET_ICB_INDUSTRIES:
      return {
        ...state,
        icbIndustries: action.payload
      };
    case SET_ADVANCE_SEARCH_FILTER:
      return {
        ...state,
        advanceSearchFilter: action.payload
      };
    default:
      return state;
  }
};
