import iconCompanyInformation from '../assets/images/icon-company-information.svg';
import iconOwnership from '../assets/images/icon-ownership.svg';
import iconFinancial from '../assets/images/icon-financial.svg';
import iconAnalysis from '../assets/images/icon-analysis.svg';
import iconRatingCreditLimit from '../assets/images/icon-rating-credit-limit.svg';
import iconIndustry from '../assets/images/icon-industry.svg';

export const menuReport = [
  {
    text: 'Company Information',
    link: '/company-information',
    icon: iconCompanyInformation,
    component: 'company-information'
  },
  {
    text: 'Ownership & Group',
    link: '/ownership-group',
    icon: iconOwnership,
    component: 'ownership-group'
  },
  {
    text: 'Financials',
    link: '/financials',
    icon: iconFinancial,
    component: 'financials'
  },
  {
    text: 'Analysis',
    link: '/analysis',
    icon: iconAnalysis,
    component: 'analysis'
  },
  {
    text: 'Rating & Credit Limit',
    link: '/rating-credit-limit',
    icon: iconRatingCreditLimit,
    component: 'rating-credit-limit'
  },
  {
    text: 'Industry',
    link: '/industry',
    icon: iconIndustry,
    component: 'industry'
  }
];
