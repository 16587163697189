import {
  SET_LIST_SELECTED_FILTER,
  REMOVE_LIST_SELECTED_FILTER,
  CLEAR_ALL_FILTER_SELECTED,
  SET_TOTAL_RESULT,
  SET_DATA_QUERY_BASIC_INFORMATION,
  SET_DATA_QUERY_INDUSTRIES,
  SET_DATA_QUERY_GROUP,
  SET_DATA_QUERY_FINANCIAL,
  SET_ACTIVE_SCREEN,
  SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW,
  SET_DATA_SELECTED_INFORMATION,
  SET_DATA_VIEW_SETTING,
  SET_DATA_CURRENCY,
  SET_SCREENING_ID,
  SET_DATA_SEARCH_RESULT,
  SET_SAVED_LIST_ITEM,
  SET_ACTIVE_MODAL,
  SET_NUMBER_SELECTED_COMPANIES,
  SET_DISCOUNT_VIEW_SETTING
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_LIST_SELECTED_FILTER:
      return {
        ...state,
        ...action.payload,
        removeID: null,
        clearAllFilter: false
      };
    case REMOVE_LIST_SELECTED_FILTER:
      return {
        ...state,
        removeID: action?.payload?.indicatorId,
        listFilterSelected: state.listFilterSelected.filter(el => el.idCompany !== action?.payload?.idCompany)
      };
    case CLEAR_ALL_FILTER_SELECTED:
      return {
        ...state,
        removeID: null,
        clearAllFilter: true,
        listFilterSelected: [],
        listKeyTabVsicIndustry: [],
        listKeyTabIcbIndustry: [],
        keyVsicDisplaySelected: [],
        keyIcbDisplaySelected: [],
        totalResult: 0,
        basicInformation: {},
        groups: {},
        industries: {},
        financials: {},
        dataCurrency: 'VND',
        dataSelectedInformation: []
      };
    case SET_TOTAL_RESULT:
      return {
        ...state,
        totalResult: action.payload
      };
    case SET_DATA_QUERY_BASIC_INFORMATION:
      return {
        ...state,
        basicInformation: action.payload
      };
    case SET_DATA_QUERY_INDUSTRIES:
      let objectData = action.payload;
      for (var propName in objectData) {
        if (objectData[propName] === null || objectData[propName].length === 0) {
          delete objectData[propName];
        }
      }

      return {
        ...state,
        industries: objectData
      };
    case SET_DATA_QUERY_GROUP:
      return {
        ...state,
        groups: action.payload
      };
    case SET_DATA_QUERY_FINANCIAL:
      return {
        ...state,
        financials: action.payload
      };
    case SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.payload
      };
    case SET_ACTIVE_MODAL_COMPANY_INFORMATION_TO_VIEW:
      return {
        ...state,
        activeModalSelectCompanyInformation: action.payload
      };
    case SET_DATA_SELECTED_INFORMATION:
      return {
        ...state,
        dataSelectedInformation: action.payload
      };
    case SET_DATA_VIEW_SETTING:
      return {
        ...state,
        dataViewSetting: action.payload
      };
    case SET_DATA_CURRENCY:
      return {
        ...state,
        dataCurrency: action.payload
      };
    case SET_SCREENING_ID:
      return {
        ...state,
        screeningId: action.payload
      };
    case SET_DATA_SEARCH_RESULT:
      return {
        ...state,
        dataSearchResult: action.payload
      };
    case SET_SAVED_LIST_ITEM:
      return {
        ...state,
        savedListItem: action.payload
      };
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload
      };
    case SET_NUMBER_SELECTED_COMPANIES:
      return {
        ...state,
        numberSelectedCompanies: action.payload
      };
    case SET_DISCOUNT_VIEW_SETTING:
      return {
        ...state,
        dataDiscount: action.payload
      };
    default:
      return state;
  }
};
