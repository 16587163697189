import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { menuItems } from '../../../constants/Menu';

import './styles.scss';

class AuthenticatedNavigation extends Component {
  constructor(props) {
    super(props);
    const pathname = this.props.pathname;
    const activeMenu = this._getActiveMenu(pathname, menuItems) || ['', {}];
    this.state = {
      currentLocation: pathname,
      activeMenu: activeMenu[0],
      subMenu: activeMenu[1] || {}
    };
  }
  componentWillReceiveProps(nextProps) {
    const pathname = nextProps.pathname;
    const activeMenu = this._getActiveMenu(pathname, menuItems) || ['', {}];
    this.setState({
      ...this.state,
      currentLocation: pathname,
      activeMenu: activeMenu[0],
      subMenu: activeMenu[1] || {}
    });
  }
  _getActiveMenu(pathname) {
    for (const key in menuItems) {
      const submenu = menuItems[key].submenu;
      if (menuItems[key].link === pathname) {
        return [key, submenu];
      } else if (submenu !== undefined) {
        for (const childkey in submenu) {
          if (submenu[childkey].link === pathname) {
            return [key, submenu];
          }
        }
      }
    }
    return ['', {}];
  }

  render() {
    const { currentLocation, activeMenu, subMenu } = this.state;
    const primaryMenuItems = [];
    for (var key in menuItems) {
      const currentItem = (
        <li key={key} className={`${activeMenu === key ? 'active' : ''} menu-item`}>
          <Tooltip placement="right" title={menuItems[key].text}>
            <Link className="menu-link" to={menuItems[key].link}>
              <img src={menuItems[key].icon} alt={menuItems[key].icon} />
            </Link>
          </Tooltip>
        </li>
      );
      if (menuItems[key].hidden === undefined || menuItems[key].hidden === false) {
        primaryMenuItems.push(currentItem);
      }
    }
    const subMenuItems = [];
    for (var key in subMenu) {
      const currentItem = (
        <li key={key} className={currentLocation === subMenu[key].link ? 'active' : ''}>
          <Link to={subMenu[key].link}>
            <img src={subMenu[key].icon} alt={subMenu[key].icon} />
          </Link>
        </li>
      );

      if (subMenu[key].hidden === undefined || subMenu[key].hidden === false) {
        subMenuItems.push(currentItem);
      }
    }
    return (
      <div id="sidebar-container">
        <div className="nav-menu">
          <nav className="navbar navbar-default">
            <ul className="menubar">{primaryMenuItems}</ul>
          </nav>
        </div>
        {subMenuItems.length > 0 && (
          <div className="container-fluid submenu">
            <div className="nav-menu">
              <nav className="navbar navbar-default">
                <ul className="menubar submenubar">{subMenuItems}</ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AuthenticatedNavigation;
