import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import HeaderReport from './components/HeaderReport';
import Footer from '../components/Footer';
import MainMenu from '../components/MainMenu/MainMenu';
import Menureport from './components/Menu';
import './styles.scss';
import CompanyViewedConfirmation from '../../pages/CompanyViewedConfirmation';
import { TEXT_ACCURACY } from '../../constants/TextAccuracy';

class Main extends PureComponent {
  render() {
    if (this.props.location.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: this.props.location }
          }}
        />
      );
    }
    const { children } = this.props;
    return (
      <div id="layout-report" className="layout-report" data-unit="">
        <Header />
        <div id="container">
          <MainMenu history={this.props.history} pathname={this.props.history.location.pathname} />
          <section id="main-body">
            <HeaderReport />
            <Menureport location={this.props.history.location} />
            <div className="gutter-example" id="main-container">
              {children}
              <div className="accuracy">{TEXT_ACCURACY}</div>
            </div>
            <CompanyViewedConfirmation />
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Main;
