import React from 'react';
import RoutePrivateLayout from './RoutePrivateLayout';
import RoutePublicLayout from './RoutePublicLayout';
import TemplateReport from 'layout/TemplateReport';
import LayoutMain from 'layout/Main';
import LayoutReport from 'layout/Report';
import LayoutMyFiinGate from 'layout/MyFiinGate';
import LayoutGrid from 'layout/GridLayout';
import LayoutComponentGrid from 'layout/GridComponent';
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Table = React.lazy(() => import('../components/Table'));
const Reports = React.lazy(() => import('../components/Reports'));
const AdvanceSearch = React.lazy(() => import('../pages/AdvancedSearch'));
const CompanyInformation = React.lazy(() => import('../pages/CompanyInformation'));
const OwnershipGroup = React.lazy(() => import('../pages/OwnershipGroup'));
const AnalysisQuick = React.lazy(() => import('../pages/AnalysisQuick'));
const Financials = React.lazy(() => import('../pages/Financials'));
const YourAlerts = React.lazy(() => import('../pages/YourAlerts'));
const AlertSettings = React.lazy(() => import('../pages/AlertSettings'));
const Faqs = React.lazy(() => import('../pages/Faqs'));
const Feedback = React.lazy(() => import('../pages/Feedback'));
const MyFiingate = React.lazy(() => import('../pages/MyFiingate'));
const ActivityList = React.lazy(() => import('../pages/KeyInformation/activityList'));
const ViewSetting = React.lazy(() => import('../pages/AdvancedSearch/ViewSetting'));
const ScreeningConfirmation = React.lazy(() => import('../pages/AdvancedSearch/ScreeningConfirmation'));
const QuickSearch = React.lazy(() => import('../pages/QuickSearch'));
const RatingCredit = React.lazy(() => import('../pages/RatingCredit'));
const Industry = React.lazy(() => import('../pages/Industry'));
const SigninOidc = React.lazy(() => import('../pages/SigninOidc'));
const ReportRating = React.lazy(() => import('../pages/ReportRating'));
const ReportCompany = React.lazy(() => import('../pages/ReportCompany'));
const ReportBank = React.lazy(() => import('../pages/ReportBank'));
const GridLayout = React.lazy(() => import('../pages/GridLayout'));
const GridComponent = React.lazy(() => import('../pages/GridComponent'));
const AccountDetails = React.lazy(() => import('../pages/AccountDetails'));
const Security = React.lazy(() => import('../pages/Security'));
const ActivityFeed = React.lazy(() => import('../pages/ActivityFeed'));
const EmailNotificationSetting = React.lazy(() => import('../pages/EmailNotificationSetting'));
const Subscription = React.lazy(() => import('../pages/Subscription'));
const SubscriptionDetail = React.lazy(() => import('../pages/SubscriptionDetail'));
const SubscribeResult = React.lazy(() => import('../pages/SubscribeResult'));
const ViewedCompanies = React.lazy(() => import('../pages/ViewedCompanies'));
const MyDownload = React.lazy(() => import('../pages/MyDownload'));
const MyDownloadDetails = React.lazy(() => import('../pages/MyDownloadDetails'));
const SavedScreening = React.lazy(() => import('../pages/SavedScreening'));
const TopUp = React.lazy(() => import('../pages/TopUp'));
const TopUpTransaction = React.lazy(() => import('../pages/TopUpTransaction'));
const LatestReport = React.lazy(() => import('../pages/LatestReport'));
const SavedListActivities = React.lazy(() => import('../pages/SavedListActivities'));
const CompaniesNews = React.lazy(() => import('../pages/CompaniesNews'));
const NewDetail = React.lazy(() => import('../pages/NewDetail'));
const SavedList = React.lazy(() => import('../pages/SavedList'));
const SavedListDetails = React.lazy(() => import('../pages/SavedList/components/SavedListDetails'));
const Subscribe = React.lazy(() => import('../pages/Subscription/components/Subscribe'));

const routes = [
  {
    path: '/quick-search/:key',
    exact: true,
    name: 'QuickSearch',
    component: QuickSearch,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/view-setting',
    exact: true,
    name: 'ViewSetting',
    component: ViewSetting,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/screening-confirmation',
    exact: true,
    name: 'ScreeningConfirmation',
    component: ScreeningConfirmation,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/',
    exact: true,
    name: 'CompanyInformation',
    component: CompanyInformation,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/table',
    exact: true,
    name: 'Table',
    component: Table,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/reports',
    exact: true,
    name: 'Reports',
    component: Reports,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/company-information/:organCode',
    exact: true,
    name: 'CompanyInformation',
    component: CompanyInformation,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/ownership-group/:organCode',
    exact: true,
    name: 'OwnershipGroup',
    component: OwnershipGroup,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/financials/:organCode',
    exact: true,
    name: 'Financials',
    component: Financials,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/your-alerts',
    exact: true,
    name: 'YourAlerts',
    component: YourAlerts,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/alert-settings',
    exact: true,
    name: 'AlertSettings',
    component: AlertSettings,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/analysis/:organCode',
    exact: true,
    name: 'AnalysisQuick',
    component: AnalysisQuick,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/faqs',
    exact: true,
    name: 'Faqs',
    component: Faqs,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/feedback',
    exact: true,
    name: 'Feedback',
    component: Feedback,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/my-fiingate',
    exact: true,
    name: 'MyFiingate',
    component: MyFiingate,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/account-details',
    exact: true,
    name: 'AccountDetails',
    component: AccountDetails,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/security',
    exact: true,
    name: 'Security',
    component: Security,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/activity-feed',
    exact: true,
    name: 'ActivityFeed',
    component: ActivityFeed,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/email-notification',
    exact: true,
    name: 'EmailNotificationSetting',
    component: EmailNotificationSetting,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/subscription',
    exact: true,
    name: 'Subscription',
    component: Subscription,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/subscription/subscribe',
    exact: true,
    name: 'subscribe',
    component: Subscribe,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/top-up',
    exact: true,
    name: 'TopUp',
    component: TopUp,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/topup-transaction/confirm',
    exact: true,
    name: 'TopUpTransaction',
    component: TopUpTransaction,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/subscription/:detailId',
    exact: true,
    name: 'SubscriptionDetail',
    component: SubscriptionDetail,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/subscription/result/:detailId',
    exact: true,
    name: 'SubscribeResult',
    component: SubscribeResult,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/viewed-companies',
    exact: true,
    name: 'ViewedCompanies',
    component: ViewedCompanies,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/my-download',
    exact: true,
    name: 'MyDownload',
    component: MyDownload,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/my-download/:detailId',
    exact: true,
    name: 'MyDownloadDetails',
    component: MyDownloadDetails,
    layout: LayoutMyFiinGate,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/company-information/:organCode/activity-list',
    exact: true,
    name: 'ActivityList',
    component: ActivityList,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/screening',
    exact: true,
    name: 'AdvancedSearch',
    component: AdvanceSearch,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/rating-credit-limit/:organCode',
    exact: true,
    name: 'RatingCredit',
    component: RatingCredit,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/industry/:organCode',
    exact: true,
    name: 'Industry',
    component: Industry,
    layout: LayoutReport,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/signin-oidc',
    exact: true,
    name: 'SigninOidc',
    component: SigninOidc,
    layout: LayoutMain,
    layoutMiddleware: RoutePublicLayout
  },
  {
    path: '/template-report-rating/:organCode',
    exact: true,
    name: 'ReportRating',
    component: ReportRating,
    layout: TemplateReport,
    layoutMiddleware: RoutePublicLayout
  },
  {
    path: '/template-report-company/:organCode',
    exact: true,
    name: 'ReportCompany',
    component: ReportCompany,
    layout: TemplateReport,
    layoutMiddleware: RoutePublicLayout
  },
  {
    path: '/template-report-bank/:organCode',
    exact: true,
    name: 'ReportCompany',
    component: ReportBank,
    layout: TemplateReport,
    layoutMiddleware: RoutePublicLayout
  },
  {
    path: '/grid-layout/:component/:organCode/:grid',
    exact: true,
    name: 'GridLayout',
    component: GridLayout,
    layout: LayoutGrid,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/grid-component/:organCode/:component',
    exact: true,
    name: 'GridComponent',
    component: GridComponent,
    layout: LayoutComponentGrid,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/saved-screening',
    exact: true,
    name: 'SavedScreening',
    component: SavedScreening,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/latest-report/',
    exact: true,
    name: 'LatestReport',
    component: LatestReport,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/activities-list/',
    exact: true,
    name: 'SavedListActivities',
    component: SavedListActivities,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/company-news/',
    exact: true,
    name: 'CompaniesNews',
    component: CompaniesNews,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/new-detail/:newsId/:newsLangue',
    exact: true,
    name: 'NewDetail',
    component: NewDetail,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/saved-list',
    exact: true,
    name: 'SavedList',
    component: SavedList,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  },
  {
    path: '/saved-list/:savedListId',
    exact: true,
    name: 'SavedListDetail',
    component: SavedListDetails,
    layout: LayoutMain,
    layoutMiddleware: RoutePrivateLayout
  }
];

export default routes;
