import React from 'react';
import logoFiingroup from '../../../assets/images/logo-fiingroup.svg';
import { Row, Col } from 'antd';
import { URL_CONTACT, URL_TERMS_CONDITIONS, URL_PRIVACY_POLICY, URL_ABOUT_FINN_GROUP } from '../../../constants';
import './styles.scss';

function Footer() {

  return (
    <footer id="footer">
      <Row>
        <Col lg={8} xs={24} sm={24} className="footer-copyrights">
          <div className="wrap-logo-footer">
            <a href="http://fiingroup.vn/" target="_blank" rel="noopener noreferrer">
              <img src={logoFiingroup} className="logo-footer" alt="logo footer" />
            </a>
            <p>Copyrights © 2019 FiinGroup. All rights reserved</p>
          </div>
        </Col>
        <Col lg={8} xs={24} sm={24} className="company-social">
          <div className="footer-company">
            <h4>Company</h4>
            <p>
              <a href={URL_ABOUT_FINN_GROUP} target="_blank" rel="noopener noreferrer">
                About FiinGroup
              </a>
            </p>
            <p>
              <a href={URL_CONTACT} target="_blank" rel="noopener noreferrer">
                Contact Us
              </a>
            </p>
          </div>

          <div className="footer-social">
            <h4>Social Network</h4>
            <a
              title="facebook"
              href="https://www.facebook.com/FiinGroupJSC/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-social facebook"
            >
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a
              title="twitter"
              href="https://twitter.com/FiinGroup_Corp"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-social twitter"
            >
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a
              title="linkedin"
              href="https://www.linkedin.com/company/fiingroup/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-social linkedin"
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </div>
        </Col>
        <Col lg={8} xs={24} sm={24} className="footer-bottom">
          <ul className="footer-menu">
            <li>
              <a href={URL_TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </li>
            <li>
              <span>/</span>{' '}
              <a href={URL_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                {' '}
                Privacy Policy{' '}
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
