import gql from 'graphql-tag';
export const HEADER_REPORT = gql`
  query companyReport($organCode: String!) {
    companyReport(organCode: $organCode) {
      yearReport
      organName
      en_OrganName
      en_OrganShortName
      taxCode
      fiinNumber
      incorporationDate
      telephone
      email
      fax
      website
      legalRepresentative {
        fullName
        en_FullName
      }
      en_LegalFormName
      en_BusTypeName
      vsicCode
      icbCode
      numberOfEmployee
      charterCapital {
        rateType
        value
      }
      fiscalYearEnd
      isAudit
      status
      adverseMedia
      enterpriseCode
      en_RegisteredAddress
      hasVsic
      updateDate
      comTypeCode
      vsicCodeLevel3
      geoLocation {
        address
        confidence
        en_Address
        latitude
        longitude
      }
    }
  }
`;
