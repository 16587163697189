import iconMenuSave from '../assets/images/icon-menu-save.svg';
import iconMenuRectangle from '../assets/images/icon-menu-rectangle.svg';
import iconMenuZoom from '../assets/images/icon-menu-zoom.svg';
import iconMenuList from '../assets/images/icon-menu-list.svg';

export const menuItems = {
  dashboard: {
    text: 'Dashboard',
    link: '/dashboard',
    icon: iconMenuRectangle
  },
  advanceSearch: {
    text: 'Screening',
    link: '/screening',
    icon: iconMenuZoom,
    hidden: false
  },
  savedscreening: {
    text: 'Saved Screening',
    link: '/saved-screening',
    icon: iconMenuSave,
    hidden: false
  },
  savedlists: {
    text: 'Saved Lists',
    link: '/saved-list',
    icon: iconMenuList,
    hidden: false
  }
};
