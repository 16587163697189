import React from 'react';

const TooltipDiscount = props => {
  if (props.discounts.length > 0) {
    return (
      <div>
        <p>Your highest discount is applied for this transaction.</p>
        <p>Your current discount(s) include:</p>
        <ol>
          {props.discounts.map((value, index) => {
            return <li key={index}>{`${100 * value.discountRate}% ${value.en_DiscountName}`}</li>;
          })}
        </ol>
      </div>
    );
  } else {
    return <p>No discount</p>;
  }
};

export default TooltipDiscount;
