import Request from '../utils/Request';

export function getFinancialStatements(OrganCode, param) {
  const data = {
    OrganCode,
    ...param
  };

  return Request.get('/FinancialStatements/GetFinancialStatements', data);
}

export function exportFinancialStatements(OrganCode, ExternalSourceName, IsAudit, IsConsolidated, IsDirect) {
  const data = {
    OrganCode,
    ExternalSourceName,
    IsAudit,
    IsConsolidated,
    IsDirect
  };

  return Request.get('/FinancialStatements/ExportFinancialStatements', data, { responseType: 'arraybuffer' });
}

export function exportRatingReport(Url, OrganCode, param) {
  const data = {
    Url,
    OrganCode,
    ...param
  };

  return Request.post('/ExportPdf/ExportRatingReport', data, { responseType: 'arraybuffer' });
}

export function exportCompanyReport(OrganCode, Source, IsAudit, IsConsolidated, IsDirect, Currency, Unit) {
  const data = {
    OrganCode,
    Source,
    IsAudit,
    IsConsolidated,
    IsDirect,
    Currency,
    Unit
  };

  return Request.get('/ExportPdf/ExportCompanyReport', data, { responseType: 'arraybuffer' });
}

export function exportTransactionDetails(transactionId) {
  const data = {
    transactionId
  };

  const link = '/ExportTransactionDetails/DownloadConfirmations';

  return Request.get(link, data, { responseType: 'arraybuffer' });
}

export function DownloadMydownloadDetails(TransactionId, param) {
  const data = {
    TransactionId,
    ...param
  };

  return Request.get('/DownloadDocument/GetFile', data, { responseType: 'arraybuffer' });
}

export function DownloadSaveListDetails(organCodes, savedListName, param) {
  const data = {
    organCodes: organCodes,
    savedListName: savedListName,
    ...param
  };

  return Request.get('/ExportSavedListDetails/ExportSavedListDetails', data, { responseType: 'arraybuffer' });
}

export function DownloadAllSaveListDetails(savedListId, numberOfCompanies, savedListName, excludeOrganCodes, param) {
  const data = {
    savedListId: savedListId,
    numberOfCompanies: numberOfCompanies,
    savedListName: savedListName,
    excludeOrganCodes: excludeOrganCodes,
    ...param
  };

  return Request.get('/ExportSavedListDetails/ExportAllSavedListDetails', data, { responseType: 'arraybuffer' });
}

export function downloadCreditReportSample(ReportType) {
  const data = {
    ReportType
  };

  return Request.get('/Rating/DownloadCreditReportSample', data, { responseType: 'arraybuffer' });
}

export function requestForCreditAnalysisReport(OrganCode) {
  const data = {
    OrganCode
  };

  return Request.get('/Rating/RequestForCreditAnalysisReport', data);
}

export function requestForIntrinsicCreditScore(OrganCode) {
  const data = {
    OrganCode
  };

  return Request.get('/Rating/RequestForIntrinsicCreditScore', data);
}
