import iconAccountDetails from '../assets/images/icon-account-detail.svg';
import iconSecurity from '../assets/images/icon-security.svg';
import iconActivityFeed from '../assets/images/icon-activity-feed.svg';
import iconEmailNotification from '../assets/images/icon-email-notification.svg';
import iconSubscription from '../assets/images/icon-subscription.svg';
import iconViewedCompanies from '../assets/images/icon-viewed-companies.svg';
import iconMyDownload from '../assets/images/icon-my-download.svg';

export const MenuMyFiinGate = [
  {
    text: 'Account Details',
    link: '/account-details',
    icon: iconAccountDetails,
    component: 'account-details'
  },
  {
    text: 'Security',
    link: '/security',
    icon: iconSecurity,
    component: 'security'
  },
  {
    text: 'Activity Feed',
    link: '/activity-feed',
    icon: iconActivityFeed,
    component: 'activity-feed'
  },
  {
    text: 'Email Notification',
    link: '/email-notification',
    icon: iconEmailNotification,
    component: 'email-notification'
  },
  {
    text: 'Subscription',
    link: '/subscription',
    icon: iconSubscription,
    component: 'subscription'
  },
  {
    text: 'Viewed Companies',
    link: '/viewed-companies',
    icon: iconViewedCompanies,
    component: 'viewed-companies'
  },
  {
    text: 'My Download',
    link: '/my-download',
    icon: iconMyDownload,
    component: 'my-download'
  },
];
