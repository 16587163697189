import gql from 'graphql-tag';

export const INDUSTRIES = gql`
  query {
    vsicIndustries {
      en_VsicName
      en_FriendlyName
      en_SectorProfile
      en_VsicNamePath
      status
      vsicCode
      vsicName
      vsicLevel
      parentVsicCode
    }
    icbIndustries {
      en_IcbName
      en_IcbNamePath
      en_IcbShortName
      icbCode
      parentIndustryID
      parentIcbCode
      icbLevel
    }
  }
`;
