import { WebStorageStateStore } from 'oidc-client';

export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: window.location.origin + '/signin-oidc',
  response_type: 'id_token token',
  scope: 'openid FiinGroup.FiinGate',
  post_logout_redirect_uri: window.location.origin,
  silent_redirect_uri: window.location.origin + '/silent.html',
  automaticSilentRenew: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  webAuthResponseType: 'id_token token'
};
