import gql from 'graphql-tag';

export const SAVE_LIST_DELETE = gql`
  mutation savelistDelete($savelistId: Int!) {
    savelistDelete(savelistId: $savelistId)
  }
`;

export const CREATE_NEW_LIST = gql`
  mutation screeningCreateSaveList($name: String!) {
    screeningCreateSaveList(name: $name)
  }
`;

export const SAVE_LIST_DUPLICATE = gql`
  mutation savelistDuplicate($saveListId: Int!) {
    savelistDuplicate(saveListId: $saveListId)
  }
`;

export const SAVE_LIST_RENAME = gql`
  mutation savelistRename($saveListId: Int!, $savelistName: String!) {
    savelistRename(saveListId: $saveListId, savelistName: $savelistName)
  }
`;

//copy
export const SAVE_LIST_ADD_TO_SAVE_LIST = gql`
  mutation screeningAddToSaveList($organCodes: [String!]!, $saveListId: Int!) {
    screeningAddToSaveList(organCodes: $organCodes, saveListId: $saveListId)
  }
`;

export const SAVE_LIST_ADD_TO_SAVE_LIST_ALL = gql`
  mutation savedListDetailsAddAllToSaveList($excludedOrganCodes: [String!]!, $saveListId: Int!, $destinationId: Int!) {
    savedListDetailsAddAllToSaveList(excludedOrganCodes: $excludedOrganCodes, saveListId: $saveListId, destinationId:$destinationId)
  }
`;

// delete
export const SAVE_LIST_DETAIL_DELETE = gql`
  mutation savelistDetailDelete($organCodes: [String!]!, $saveListId: Int!) {
    savelistDetailDelete(organCodes: $organCodes, saveListId: $saveListId)
  }
`;

export const SAVE_LIST_DETAIL_DELETE_ALL = gql`
  mutation savedListDetailsDeleteAllInSaveList($excludedOrganCodes: [String!]!, $saveListId: Int!) {
    savedListDetailsDeleteAllInSaveList(excludedOrganCodes: $excludedOrganCodes, saveListId: $saveListId)
  }
`;

// move all = copyall + deleteall
export const MOVE_SAVE_LIST_DETAIL = gql`
  mutation savelistDetailMove($savedListDetailIds: [Int!]!, $savedListId: Int!) {
    savelistDetailMove(savedListDetailIds: $savedListDetailIds, savedListId: $saveListId)
  }
`;
