import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import logo from '../../../assets/images/logo.svg';
import iconSearch from '../../../assets/images/icon-search.svg';
import iconNotifications from '../../../assets/images/icon-notifications.svg';
import iconUser from '../../../assets/images/icon-user.svg';
import { withRouter } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import { formatDateTime } from '../../../mixins/DateFormat';
import { formatStandardNumber } from '../../../mixins/NumberFormat';
import { CURRENT_BALANCE } from '../../../graphql/queries/CurrentBalance';
import { useApolloClient, useMutation, useLazyQuery } from '@apollo/react-hooks';
import GlobalContext from 'context/global/globalContext';
import { ALERT_NOTIFICATION } from 'graphql/queries/AlertNotification';
import { ALERT_MARK_AS_READ } from 'graphql/mutations/AlertMarkAsRead';
import { FEATURE_LIST_FEEDBACK } from 'graphql/queries/Feedback';
import AlertBox from '../../../components/AlertBox';
import GiveFeedback from '../../../components/GiveFeedback';
import NoData from '../../../components/NoData';
import { getUser } from 'utils/userManager';
import useDocumentScrollThrottled from 'mixins/UseDocumentScrollThrottled';

import './styles.scss';

const Header = props => {
  const [userProfile, setUserProfile] = useState();
  const [showModal, setShowModal] = useState(false);
  const authContext = useContext(AuthContext);
  const globalContext = useContext(GlobalContext);
  const {
    currentBalance,
    setCurrentBalance,
    setListIdAlertReaded,
    totalUnRead,
    setTotalUnRead,
    alertNotificationGlobal,
    setAlertNotificationGlobal
  } = globalContext;
  const { logout } = authContext;

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const MINIMUM_SCROLL = 70;
  const TIMEOUT_DELAY = 300;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    // const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const shadowStyle = shouldShowShadow ? 'shadow' : '';
  const hiddenStyle = shouldHideHeader ? 'hidden' : '';

  const [loadFeedBack, { data: listFeedBack, loading }] = useLazyQuery(FEATURE_LIST_FEEDBACK);

  const history = useHistory();
  const hrefLink = '#';
  const client = useApolloClient();
  // const getStoreKey = `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
  // const oidcStorage = JSON.parse(localStorage.getItem(getStoreKey));
  // const userProfile = oidcStorage?.profile ? oidcStorage.profile : {};

  const [getCurrentBalance, { data }] = useLazyQuery(CURRENT_BALANCE);

  useEffect(() => {
    setCurrentBalance(data?.currentBalance?.currentBalance);
  }, [data]);

  useEffect(() => {
    if (shouldHideHeader === true) {
      document.body.classList.add('hiden-header');
    } else {
      document.body.classList.remove('hiden-header');
    }
  }, [shouldHideHeader]);

  const credit = currentBalance ?? 0;
  const [dataAlerts, setDataAlerts] = useState([]);
  const [getAlertNotification, { data: alertNotification }] = useLazyQuery(ALERT_NOTIFICATION);
  const MAX_UNREAD = 99;
  const menuInforUser = document.querySelector('.infor-user');
  const menuListAlert = document.querySelector('.alert-noti');
  const feedbackForm = document.querySelector('.feedback');
  const body = document.querySelector('body');
  const [alertMarkAsRead] = useMutation(ALERT_MARK_AS_READ);
  const [arrayAlertId, setArrayAlertId] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);

  useEffect(() => {
    if (Object.keys(alertNotificationGlobal).length === 0) {
      getAlertNotification({
        variables: { page: 1, pageSize: 1000 }
      });
    }

    getUser().then(user => {
      const profile = user?.profile ? user.profile : {};
      setUserProfile(profile);
    });
  }, []);

  useEffect(() => {
    if (alertNotification) {
      setAlertNotificationGlobal(alertNotification?.alertNotification);
      setTotalUnRead(alertNotification?.alertNotification?.items.filter(el => el.isRead === false).length);
      setDataAlerts(alertNotification?.alertNotification?.items.slice(0, 20));
    }
  }, [alertNotification]);

  useEffect(() => {
    if (isShowAlert && alertNotification) {
      const alertIds = dataAlerts.filter(item => !item.isRead).map(item => item.alertId);
      setListIdAlertReaded(alertIds);
      setArrayAlertId(alertIds);

      if (alertIds.length > 0) {
        alertMarkAsRead({ variables: { alertIds: alertIds } }).then(() => {
          setTotalUnRead(totalUnRead >= alertIds.length ? totalUnRead - alertIds.length : 0);
        });
      }
    }
  }, [dataAlerts, isShowAlert]);

  useEffect(() => {
    setCurrentBalance(credit);
  }, [credit]);

  const showInfo = () => {
    getCurrentBalance();
    // const menu = document.querySelector('.infor-user'); // Using a class instead, see note below.
    menuInforUser.classList.toggle('active');
  };

  const showAlert = () => {
    setIsShowAlert(true);
    menuInforUser.classList.remove('active'); // Hidden menu info user.
    menuListAlert.classList.toggle('active');
    getAlertNotification({
      variables: { page: 1, pageSize: 1000 }
    });
  };

  const nextToYourAlerts = () => {
    const alertIds = alertNotification?.alertNotification.items.map(el => el.alertId);

    alertMarkAsRead({ variables: { alertIds: alertIds } }).then(result => {
      if (result.data.alertMarkAsRead) {
        setTotalUnRead(0);
      }
    });
  };

  const showModalConfirm = () => {
    setShowModal(true);
  };

  const handleOk = () => {
    // localStorage.removeItem('redirectUri');
    logout();
    setShowModal(false);
  };

  const handleCancel = e => {
    setShowModal(false);
  };

  const searchHandleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (e.target.value !== '') {
        window.open(`/quick-search/${encodeURIComponent(e.target.value)}`, '_blank');
      }
    }
  };

  const showFormFeedback = () => {
    loadFeedBack();
    if (!loading) {
      menuInforUser.classList.remove('active'); // Hidden menu info user.
      feedbackForm.classList.add('active');
      body.classList.add('display-overflow');
    }
  };

  const hidenAlert = () => {
    menuListAlert.classList.remove('active');
    setIsShowAlert(false);
  };

  const hidenInforUser = () => {
    menuInforUser.classList.remove('active');
  };

  return (
    <header id="header">
      <div className="wrap-logo">
        <Link className="logo-link" to="/dashboard">
          <img src={logo} id="logo" alt="logo" />
        </Link>
      </div>
      <div className="header-search">
        <button className="btn-search-header">
          <img src={iconSearch} alt="icon search" />
        </button>
        <input
          type="text"
          className="search-all"
          placeholder="Find a company - Enter a company name, tax code or FiinNumber"
          onKeyDown={searchHandleKeyDown}
        />
      </div>
      <div className="header-account">
        <div className="header-notification">
          <div onClick={showAlert} className="cursor">
            {totalUnRead > 0 ? (
              <span id="totalUnRead" className={`total-noti ${totalUnRead > MAX_UNREAD ? 'total-noti-customs' : ''}`}>
                {totalUnRead > MAX_UNREAD ? `${MAX_UNREAD}+` : totalUnRead}
              </span>
            ) : (
              <span id="totalUnRead"></span>
            )}
            <img src={iconNotifications} alt="icon notifications" className="icon-alert" />
          </div>

          <div className="alert-noti">
            <div className="alert-wrapper">
              <ul className={`alert-list alert-list-scroll`}>
                {dataAlerts && dataAlerts.length > 0 ? (
                  dataAlerts.map((el, index) => <AlertBox key={index} alert={el} arrayAlertId={arrayAlertId} />)
                ) : (
                  <NoData />
                )}
              </ul>
              <div className="alert-action">
                <span className={`${!dataAlerts || dataAlerts.length === 0 ? 'isDisable' : ''}`}>
                  <Link to="/your-alerts" className="view-all" onClick={nextToYourAlerts}>
                    View All
                  </Link>
                </span>
                <Link to="/alert-settings" className="alert-settings">
                  Alert Settings
                </Link>
              </div>
            </div>
            <div className="alert-overlay" onClick={hidenAlert}></div>
          </div>
        </div>

        <div className="header-profile">
          <img src={iconUser} alt="icon user" className="icon-user" onClick={showInfo} />

          <div className="infor-user">
            <div className="infor-user-wrapper">
              <div className="infor-acc">
                <div className="fullname">
                  <h2>{userProfile?.name}</h2>
                </div>
                <div className="infor-credit">
                  <p className="credit">{formatStandardNumber(currentBalance, true)} Credit</p>
                  <p className="expiry-date">Expiry Date: {formatDateTime(data?.currentBalance?.endDate)}</p>
                </div>
              </div>
              <ul>
                <li>
                  <Link to="/account-details">My FiinGate</Link>
                </li>
                <li>
                  <a href={hrefLink} onClick={showFormFeedback}>
                    Give Feedback
                  </a>
                </li>
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li>
                  <a className="sign-out" href={hrefLink} onClick={showModalConfirm}>
                    Sign Out
                  </a>
                  <Modal title="Sign out" visible={showModal} onOk={handleOk} onCancel={handleCancel} cancelText="No">
                    <p>Are you sure you want to sign out ?</p>
                  </Modal>
                </li>
              </ul>
            </div>
            <div className="infor-user-overlay" onClick={hidenInforUser}></div>
          </div>
        </div>
      </div>
      <GiveFeedback listFeedBack={listFeedBack} />
    </header>
  );
};

export default withRouter(Header);
