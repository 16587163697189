import { IDENTITY_CONFIG } from './authConst';
import { UserManager } from 'oidc-client';

export const userManager = new UserManager(IDENTITY_CONFIG);

export async function getUser() {
  const user = await userManager.getUser();
  return user;
}

export default {
  userManager,
  getUser
};
