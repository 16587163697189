import React, { useEffect, useContext, useState, useRef } from 'react';
import GlobalContext from './context/global/globalContext';
import { EXCHANGE_RATES } from './graphql/queries/ExchangeRates';
import { INDUSTRIES } from './graphql/queries/Industries';
import {
  ADVANCED_SEARCH_LIST_FILTER,
  DATA_LEGAL_FORMS,
  DATA_OPTION_LOCATION,
  DATA_COMPANY_REPORT_LEGAL_REGULATIONS
} from 'graphql/queries/AdvancedSearch';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import ListSelectedFilterState from './context/listSelectedFilter/ListSelectedFilterState';
import WarningTabletMobile from './components/WarningTabletMobile';
import AppRouter from './routes';
import AuthContext from 'context/auth/authContext';
import ModalNotification from 'components/ModalNotification';
import { CURRENT_BALANCE } from 'graphql/queries/CurrentBalance';

function App() {
  const pathname = window.location.pathname.split('/')[1];
  const globalContext = useContext(GlobalContext);
  const {
    setExchangeRates,
    setIndustries,
    setAdvanceSearchFilter,
    setLegalForms,
    setLocation,
    setLegalRegulation,
    setCurrentBalance
  } = globalContext;
  const [loadExchangeRates, { data: exchangeRates }] = useLazyQuery(EXCHANGE_RATES);
  const [loadIndustries, { data: industries }] = useLazyQuery(INDUSTRIES);
  const [loadAdvanceSearchFilter, { data: advanceSearchFilter }] = useLazyQuery(ADVANCED_SEARCH_LIST_FILTER);
  const [loadLegalForms, { data: legalForms }] = useLazyQuery(DATA_LEGAL_FORMS);
  const [loadLocations, { data: locations }] = useLazyQuery(DATA_OPTION_LOCATION);
  const [loadLegalRegulation, { data: legalRegulation }] = useLazyQuery(DATA_COMPANY_REPORT_LEGAL_REGULATIONS);
  const authContext = useContext(AuthContext);
  const { logout, signinRedirect } = authContext;
  const [showNotification, setShowNotification] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const { data: dataCurrentBlance } = useQuery(CURRENT_BALANCE);

  useEffect(() => {
    loadLegalForms();
    loadLegalRegulation();

    if (localStorage.getItem('exchangeRates') === null) {
      loadExchangeRates();
    }
    if (pathname !== 'template-report-rating' && pathname !== 'template-report-company') {
      loadIndustries();
    }
    // if (localStorage.getItem('legalForms') === null) {
    //   loadLegalForms();
    // }
    if (pathname !== 'template-report-rating' && pathname !== 'template-report-company') {
      loadLocations();
    }
    // if (localStorage.getItem('legalRegulation') === null) {
    //   loadLegalRegulation();
    // }
    // if (localStorage.getItem('screeningParameters') === null) {
    loadAdvanceSearchFilter();
    // }
  }, []);

  window.onbeforeunload = function() {
    localStorage.removeItem('exchangeRates');
    localStorage.removeItem('legalForms');
    localStorage.removeItem('legalRegulation');
    localStorage.removeItem('screeningParameters');
    localStorage.removeItem('icbIndustries');
    localStorage.removeItem('vsicIndustries');
    localStorage.removeItem('locations');
  };

  useEffect(() => {
    setExchangeRates(exchangeRates);
    setIndustries(industries);
    setAdvanceSearchFilter(advanceSearchFilter);
    setLegalForms(legalForms);
    setLocation(locations);
    setLegalRegulation(legalRegulation);
    setCurrentBalance(dataCurrentBlance?.currentBalance?.currentBalance);
  }, [exchangeRates, industries, advanceSearchFilter, legalForms, locations, legalRegulation, dataCurrentBlance]);

  const contentNotification = (
    <div>A new device just signed in to your FiinGate account. You are now logged out for security reason.</div>
  );

  const closeNotificationModal = () => {
    localStorage.setItem('is-logout', false);
    setShowNotification(false);
    localStorage.setItem('logoutAll', true);
    localStorage.setItem('isLogout', true);
    localStorage.setItem('idleTimerLoggedOut', true);
    logout();
  };

  const logoutTimer = () => {
    logout();
    setTimeout(() => {
      localStorage.setItem('isLogout', true);
      localStorage.setItem('idleTimerLoggedOut', true);
    }, 100);
  };

  useEffect(() => {
    localStorage.setItem('isLogout', false);
    localStorage.setItem('logoutAll', false);

    const interval = setInterval(() => {
      if (
        localStorage.getItem('idleTimerLoggedOut') == 'true' &&
        document.hidden === false &&
        localStorage.getItem('isLogout') == 'false'
      ) {
        localStorage.setItem('isLogout', true);
        logoutTimer();
        // localStorage.setItem('redirectUri', window.location.href);
      } else if (
        localStorage.getItem('idleTimerLoggedOut') == 'true' &&
        document.hidden === true &&
        localStorage.getItem('isLogout') == 'false'
      ) {
        setTimeout(() => {
          localStorage.setItem('isLogout', true);
        }, 1000);
      } else if (localStorage.getItem('isLogout') == 'true' && localStorage.getItem('idleTimerLoggedOut') == 'true') {
        clearInterval(interval);
        window.addEventListener('mousemove', function() {
          if (localStorage.getItem('isLogout') == 'true' && localStorage.getItem('idleTimerLoggedOut') == 'true') {
            localStorage.setItem('isLogout', true);
            localStorage.setItem('idleTimerLoggedOut', true);
            signinRedirect();
          }
        });
      }

      setIsLogout(localStorage.getItem('is-logout') ? JSON.parse(localStorage.getItem('is-logout')) : false);

      if (JSON.parse(localStorage.getItem('is-logout'))) {
        setShowNotification(true);
      }

      if (localStorage.getItem('logoutAll') == 'true') {
        logout();
      }
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <ListSelectedFilterState>
        <AppRouter />
        <WarningTabletMobile />
      </ListSelectedFilterState>

      {isLogout && (
        <ModalNotification
          visible={showNotification}
          title=" "
          content={contentNotification}
          onOk={closeNotificationModal}
          onCancel={closeNotificationModal}
        />
      )}
    </React.Fragment>
  );
}

export default App;
