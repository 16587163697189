import gql from 'graphql-tag';

export const GET_DISCOUNT_QUERY = gql`
  query discounts($transactionTypeId: Int!) {
    discounts(transactionTypeId: $transactionTypeId) {
      en_DiscountName
      discountRate
    }
  }
`;
