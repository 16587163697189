import gql from 'graphql-tag';

export const CURRENT_BALANCE = gql`
  query {
    currentBalance {
      currentBalance
      endDate
    }
  }
`;
