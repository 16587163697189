import React, { memo } from 'react';
import { Button, Modal } from 'antd';
import './styles.scss';

const ModalNotification = memo(
  ({ visible, title, content, onOk, onCancel, okText = 'Close', maskClosable = false }) => {
    return (
      <Modal
        title={title}
        visible={visible}
        width="55%"
        maskClosable={maskClosable}
        wrapClassName="modal modal-hide-footer"
        onCancel={onCancel}
      >
        <div className="notification">
          <div className="content">{content}</div>

          <div className="modal-footer">
            <Button type="primary" className="ok" onClick={onOk}>
              {okText}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalNotification;
