import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import CompanyViewedConfirmation from '../../pages/CompanyViewedConfirmation';

import './styles.scss';

class GridLayout extends PureComponent {
  render() {
    if (this.props.location.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: this.props.location }
          }}
        />
      );
    }
    const { children } = this.props;
    return (
      <div className="layout-grid-component">
        <div id="container">
          <section id="main-body">
            <div className="gutter-example" id="main-container">
              {children}
            </div>
            <CompanyViewedConfirmation />
          </section>
        </div>
      </div>
    );
  }
}

export default GridLayout;
