import React from 'react';
import Logo from '../../assets/images/logo.svg';

const WarningTabletMobile = () => {
  return (
    <div className="warning-tablet-mobile">
      <div className="content">
        <img src={Logo} alt="logo" />
        <div className="infor">
          <p>FiinGate currently does NOT support Phones and Tablets</p>
        </div>
      </div>
    </div>
  );
};

export default WarningTabletMobile;
